import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { ImageList } from "../../../../config/ImageList";
import CardHeading from "../../../headings/CardHeading";
import { ImageAndRoleWrapper } from "./Style";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../firebaseConfig";
import { Image, message, Spin, Upload } from "antd";

const ImageAndRoleCard = ({ handleChange, values, initialValues }) => {
  const [loading, setLoading] = useState(false);
  //   ============================================
  //   ===================Functions================
  //   ============================================

  // before upload function
  // this check if the photo is jpg/jpeg/png/webp and is less than 10mb
  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/WEBP file!");
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("Image must smaller than 10MB!");
    }
    return isJpgOrPng && isLt10M;
  };
  // upload image to firebase and update the state value using handleChange
  function handleImageUploadToFirebase(value) {
    const { file } = value;
    console.log("FILE SELECTED==>", value);
    const storageRef = ref(
      storage,
      `/profilePicture/${file.name}-${Date.now()}`
    ); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setLoading(true);
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        ); // update progress
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          const imageValue = {
            url,
            reference: `/profilePicture/${file.name}-${Date.now()}`,
            isDefault: false,
          };
          handleChange({
            target: {
              name: "profilePicture",
              value: imageValue,
            },
          });
          setLoading(false);
        });
      }
    );
  }

  // function to remove the image and set back to default
  const handleRemoveImage = () => {
    handleChange({
      target: {
        name: "profilePicture",
        value: initialValues?.profilePicture,
      },
    });
  };

  return (
    <ImageAndRoleWrapper>
      {/* heading */}
      <CardHeading
        title={values?.role === "brand" ? "Brand Logo" : "Profile Picture"}
        weight="700"
      />
      {/* separator */}
      <div className="mb1 mt1" />
      {/* display image when uploaded otherwise show default image */}
      {loading ? (
        <div className="flexCenter profile_picture loadingBg mt1">
          <Spin className="mtp5" />
          <Typography variant="caption">Uploading...</Typography>
        </div>
      ) : (
        <Image
          width="100%"
          src={
            values?.profilePicture?.isDefault
              ? ImageList.DefaultProfilePicture
              : values?.profilePicture?.url
          }
          alt="profile avatar"
          className="profile_picture mt1o"
          preview={{
            maskClassName: "preview",
          }}
        />
      )}
      {/* upload and remove button */}
      <div className="mt4" style={{ display: "flex" }}>
        <Upload
          maxCount={1}
          className="smallUploader"
          multiple={false}
          showUploadList={false}
          customRequest={handleImageUploadToFirebase}
          beforeUpload={beforeUpload}
          disabled={loading}
        >
          <Button
            disabled={loading}
            variant="contained"
            className="edit_button"
            disableElevation
          >
            Edit
          </Button>
        </Upload>
        <Button
          onClick={handleRemoveImage}
          variant="outlined"
          className="remove_button"
          disableElevation
        >
          Remove
        </Button>
      </div>
    </ImageAndRoleWrapper>
  );
};

export default ImageAndRoleCard;
