import { AppBar, Avatar, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { FontType } from "../../../config/Values";
const MessageAreaNav = ({ user, isRoom }) => {
  return (
    <NavWrapper position="sticky" elevation={0}>
      <div className="flexBetween">
        <Avatar src={user?.imageUrl} alt={user?.firstName} />
        <div className="name">
          <Typography variant="body1" className="nameText">
            {user?.firstName} {user?.lastName}{" "}
          </Typography>
        </div>
      </div>
      {!isRoom && (
        <Typography variant="body1" className="roleText">
          {user?.role}
        </Typography>
      )}
    </NavWrapper>
  );
};

export default MessageAreaNav;

const NavWrapper = styled(AppBar)`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between !important;
  padding: 0.5rem;
  background-color: ${Colors.faintedWhiteBg} !important;
  color: ${Colors.textBlack} !important;
  z-index: 2;
  position: absolute;
  top: 0;
  .name {
    margin-left: 1rem;
  }
  .nameText {
    color: ${Colors.textBlack} !important;
    font-family: ${FontType.openSans}, sans-serif !important;
    font-weight: 500 !important;
  }
  .roleText {
    color: ${Colors.cardText} !important;
    font-family: ${FontType.openSans}, sans-serif !important;
    font-weight: 500 !important;
    text-transform: capitalize;
  }
`;
