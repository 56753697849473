import { Avatar, Typography } from "@mui/material";
import React from "react";
import { CategoriesList } from "../../../config/DummyData";
import { Icons } from "../../../config/IconsList";
import ViewAllButton from "../../Button/ViewAllButton";
import CardHeading from "../../headings/CardHeading";
import NormalText from "../../headings/NormalText";
import { CategoryCardWrapper } from "./Style";

const HomeScreenCategoryCard = () => {
  return (
    <CategoryCardWrapper>
      <div className="flexBetween">
        <CardHeading title="Categories" />
        <ViewAllButton />
      </div>
      {CategoriesList.slice(0, 6).map((item, i) => {
        return (
          <div className="parent mt2" key={i}>
            <Avatar
              variant="rounded"
              src={item.imageUrl}
              alt={item.categoryName}
            />
            <div className="flexBetween info">
              <div style={{ flex: 1 }}>
                <NormalText title={item.categoryName} />
                <Typography className="categoryInfo">
                  {item.totalCampaigns} total campaigns, {item.currentRunning}{" "}
                  active
                </Typography>
              </div>

              <Icons.RightArrowIcon
                style={{ fontWeight: 900, cursor: "pointer" }}
              />
            </div>
          </div>
        );
      })}
    </CategoryCardWrapper>
  );
};

export default HomeScreenCategoryCard;
