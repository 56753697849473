import { Col, Empty, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ImageList } from "../../../config/ImageList";
import { LocationData, LocationState } from "../../../config/Location";
import { CategoryList } from "../../../config/Values";
import LabelInfo from "../../inputLabel/LabelInfo";
import NormalLabel from "../../inputLabel/NormalLabel";

const { Option } = Select;

const BrandInfoForm = ({ values, handleChange, touched, errors }) => {
  const [cityList, setCityList] = useState([]);
  const [dataToRender, setDataToRender] = useState([]);
  const [stateDataToRender, setStateDataToRender] = useState([]);
  const [searchText, setSearchText] = useState("");
  // fieldName and its value is accepted as props
  //  and passed to values using handleChange method
  const handleSpecificChange = (e, fieldName) => {
    handleChange({
      target: {
        name: fieldName,
        value: e,
      },
    });
    setSearchText("");
  };

  const handleSearchState = (newValue) => {
    setSearchText(newValue);
    const filteredState = LocationState?.filter((location) =>
      location.toLowerCase().includes(newValue.toLowerCase())
    );
    setStateDataToRender(filteredState);
  };
  useEffect(() => {
    // get the array corresponding to the matched state
    const filteredState = LocationData[values?.state];
    console.log("CITY LIST==>", cityList);
    // set the value in cityList to render when there is nothing typed in city search
    setCityList(filteredState);
  }, [values?.state]);

  const handleSearchCity = (newValue) => {
    setSearchText(newValue);
    // filter the city based on the the search input
    const filteredCity = cityList?.filter((location) =>
      location.toLowerCase().includes(newValue.toLowerCase())
    );
    setDataToRender(filteredCity);
  };

  return (
    <FormWrapper>
      {/* 1st row */}
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <NormalLabel title="Tagline" identifier="tagLine" />
          <Input
            size="large"
            status={touched?.tagLine && errors?.tagLine ? "error" : null}
            type="text"
            id="tagLine"
            name="tagLine"
            value={values?.tagLine}
            onChange={handleChange}
          />
        </Col>
        {/* <Col xs={24} md={12} lg={12}>
          <NormalLabel title="Head Quarter" identifier="headQuarters" />
          <Input
            size="large"
            status={
              touched?.headQuarters && errors?.headQuarters ? "error" : null
            }
            type="text"
            id="headQuarters"
            name="headQuarters"
            value={values?.headQuarters}
            onChange={handleChange}
          />
        </Col> */}
      </Row>
      {/* state and city selection */}
      <Row gutter={[24, 24]} className="mt1">
        <Col xs={24} md={12} lg={12}>
          <NormalLabel title="State" identifier="state" />
          <Select
            status={touched.state && errors.state ? "error" : null}
            showSearch
            value={values?.state}
            size="large"
            placeholder="Select State"
            style={{
              width: "100%",
            }}
            onChange={(e) => handleSpecificChange(e, "state")}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearchState}
            notFoundContent={<Empty />}
            options={(searchText === ""
              ? LocationState?.slice(0, 29)
              : stateDataToRender
            )?.map((d) => ({
              value: d,
              label: d,
            }))}
          />
        </Col>
        <Col xs={24} md={12} lg={12}>
          <NormalLabel title="City" identifier="city" />
          <Select
            status={touched.city && errors.city ? "error" : null}
            showSearch
            value={values?.city}
            size="large"
            placeholder="Select City"
            style={{
              width: "100%",
            }}
            disabled={values?.state === ""}
            onChange={(e) => handleSpecificChange(e, "city")}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearchCity}
            notFoundContent={<Empty />}
            options={(searchText === ""
              ? cityList?.slice(0, 30)
              : dataToRender
            )?.map((d) => ({
              value: d,
              label: d,
            }))}
          />
        </Col>
      </Row>
      {/* 2nd row */}
      <Row gutter={[24, 24]} className="mt1">
        <Col span={24}>
          <NormalLabel title="Website Url" identifier="websiteUrl" />
          <Input
            size="large"
            addonBefore="www."
            status={touched?.websiteUrl && errors?.websiteUrl ? "error" : null}
            type="text"
            id="websiteUrl"
            name="websiteUrl"
            value={values?.websiteUrl}
            onChange={handleChange}
          />
        </Col>
      </Row>
      {/* 3rd row */}
      <Row gutter={[24, 24]} className="mt1">
        <Col xs={24} md={12} lg={12}>
          <NormalLabel
            title="Instagram Profile Link"
            identifier="instagramProfile"
          />
          <Input
            size="large"
            status={
              touched?.instagramProfile && errors?.instagramProfile
                ? "error"
                : null
            }
            type="text"
            id="instagramProfile"
            name="instagramProfile"
            value={values?.instagramProfile}
            onChange={handleChange}
          />
        </Col>
        <Col xs={24} md={12} lg={12}>
          <NormalLabel title="LinkedIn Id" identifier="linkedIn" />
          <Input
            size="large"
            status={touched?.linkedIn && errors?.linkedIn ? "error" : null}
            type="text"
            id="linkedIn"
            name="linkedIn"
            value={values?.linkedIn}
            onChange={handleChange}
          />
        </Col>
      </Row>
      {/* 3rd row */}
      <Row gutter={[24, 24]} className="mt1">
        <Col xs={24} md={24} lg={24}>
          <LabelInfo
            info="The categories in which this brand operates."
            title="Categories"
            identifier="categories"
          />
          <Select
            status={touched.categories && errors.categories ? "error" : null}
            id="categories"
            onChange={(e) => handleSpecificChange(e, "categories")}
            showArrow
            style={{ width: "100%" }}
            allowClear
            suffixIcon={
              <img
                src={ImageList.TagBlackImageIcon}
                alt="icon"
                className="tagsIcon"
              />
            }
            mode="multiple"
            maxTagCount="responsive"
            size="large"
          >
            {CategoryList?.map((item, i) => {
              return (
                <Option key={i} value={item.label}>
                  {" "}
                  {item.label}{" "}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
    </FormWrapper>
  );
};

export default BrandInfoForm;

const FormWrapper = styled.div`
  width: 100%;
  .tagsIcon {
    width: 15px;
    height: 15.83px;
    margin-right: 10px;
    left: 10px !important;
  }
`;
