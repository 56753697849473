import { Avatar, IconButton, Typography } from "@mui/material";
import { Empty, message, Pagination, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Colors } from "../../../../../config/Colors";
import { formatDate, numberWithCommas } from "../../../../../config/Function";
import { ImageList } from "../../../../../config/ImageList";
import { getInfluencerDetailCampaignWiseAPI } from "../../../../../functions/campaign";
import useViewport from "../../../../../viewport/useViewport";
import CardHeading from "../../../../headings/CardHeading";
import TagWithIcon from "../../../../tag/TagWithIcon";
import { PaginationWrapper } from "../../../User/Style";
import { LiveWrapper } from "./Style";
import { Icons } from "../../../../../config/IconsList";

const LiveInfluencerCampaign = ({ campaignDetail, campaignId }) => {
  const { isTablet } = useViewport();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  //   different states
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [liveFeedInfluencers, setLiveFeedInfluencers] = useState([]);
  const [toatlInfluencerCount, setToatlInfluencerCount] = useState(0);
  const [refreshing, setRefreshing] = useState(false);

  //   ==============================FUNCTIONS==================================
  // page  size change: number of item to be displayed on a single page
  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
    setPageSize(pageSize);
  };
  // change page number
  const handleChangePageNumber = (page, pageSize) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (user !== "") {
      getInfluencerList();
    }
  }, [user, currentPage, pageSize]);

  const getInfluencerList = () => {
    setRefreshing(true);

    const values = {
      page: currentPage,
      limit: pageSize,
      campaignId,
      influencerType: "submitted",
    };
    getInfluencerDetailCampaignWiseAPI(values, user?.token)
      .then((res) => {
        console.log("APPLIED INFLUENCERS", res.data);
        setLiveFeedInfluencers(res.data.influencers);
        setToatlInfluencerCount(res.data.totalCount);
        setRefreshing(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
        setRefreshing(false);
      });
  };

  //   pay the payable amount to the influencer
  const handlePayment = () => {
    if (window.confirm("Do you want to make the payment?")) {
      message.success("Influencer has been paid successfully");
    } else {
      console.log("Request canceled!!");
    }
  };
  return (
    <>
      <LiveWrapper isTablet={isTablet}>
        <div className="flexBetween">
          <CardHeading title="Live Feed" />
          <div
            onClick={getInfluencerList}
            style={{
              cursor: "pointer",
              transform: refreshing && "rotate(90deg)",
              transition: "all 3s ease-in",
            }}
          >
            {" "}
            <Icons.RefreshIcon />
          </div>
        </div>

        <>
          <table className="tableParent">
            {/* table header row */}
            <thead className="headerContainer">
              <tr>
                <th className="tableHeader">Name</th>
                <th className="tableHeader centerAlignText">Live Since</th>
                <th className="tableHeader centerAlignText">Payable</th>
                <th className="tableHeader centerAlignText">Links</th>
                <th className="tableHeader centerAlignText">Action</th>
              </tr>
            </thead>

            {/* table body */}
            <tbody>
              {liveFeedInfluencers?.slice(0, pageSize).map((item, i) => {
                return (
                  <tr key={i}>
                    {/* name and avatar */}
                    <td>
                      <a
                        href={`/user/${item?._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="tableData"
                      >
                        <Avatar
                          variant="rounded"
                          sx={{ width: 37, height: 37 }}
                          style={{ borderRadius: 10 }}
                          src={item?.profilePicture?.url}
                        />
                        <div className="influencerText">
                          <Typography className="name">
                            {" "}
                            {item?.firstName + " " + item?.lastName}{" "}
                          </Typography>
                          <Typography className="email">
                            {item?.email}
                          </Typography>
                        </div>
                      </a>
                    </td>
                    {/* live since */}
                    <td className="centerAlignText">
                      {formatDate(item?.createdAt)}
                    </td>
                    {/* payable */}
                    <td className="centerAlignText">
                      ₹{numberWithCommas(item?.payable || 0)}
                    </td>

                    {/* Links with Platform ICon  */}
                    <td className="centerAlignText flexCenter">
                      {item?.links?.map((a, i) => {
                        let imageUrl =
                          a?.type === "instaReel"
                            ? ImageList.InstaReelImageIcon
                            : a?.type === "instagramPost"
                            ? ImageList.InstagramImageIcon
                            : a?.type === "facebookPost"
                            ? ImageList.FacebookImageIcon
                            : a?.type === "youtubeShorts"
                            ? ImageList.YoutubeShortsImageIcon
                            : ImageList.YouTubeImageIcon;
                        return (
                          <a
                            key={i}
                            href={`/user/${item?._id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flexBetween"
                          >
                            <IconButton>
                              <img
                                src={imageUrl}
                                className="uploadedLinkLogo"
                                alt="logo"
                              />
                            </IconButton>
                          </a>
                        );
                      })}
                    </td>

                    {/* actions */}
                    <td className="centerAlignText">
                      <TagWithIcon
                        title="Pay Now"
                        background={Colors.tagGreenBg}
                        color={Colors.darkGreen}
                        iconUrl={ImageList.MoneyBagGreenImageIcon}
                        ifMarginNotSpecified={true}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {liveFeedInfluencers?.length < 1 && (
            <div
              className="flexCenter mt2"
              style={{
                width: "100%",
                alignSelf: "center",
              }}
            >
              <Empty />
            </div>
          )}
        </>
      </LiveWrapper>
      {/* pagination */}
      <PaginationWrapper>
        <Pagination
          pageSizeOptions={[4, 10, 50, 100, 200, 300, 500, 1000]}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={1}
          total={toatlInfluencerCount}
          onChange={handleChangePageNumber}
        />
      </PaginationWrapper>
    </>
  );
};

export default LiveInfluencerCampaign;
