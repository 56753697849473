import React from "react";
import { CreatorWrapper } from "./CreatorStyle";
import DesktopLayout from "../../theme/DesktopLayout";
import AllUserTable from "../../components/table/User/AllUserTable";

const TopCreator = () => {
  return (
    <DesktopLayout>
      <CreatorWrapper>
        <AllUserTable topcreatorPage={true} />
      </CreatorWrapper>
    </DesktopLayout>
  );
};

export default TopCreator;
