import React from "react";
import { useDispatch, useSelector } from "react-redux";
import NavigatorDesktop from "../components/nav/NavigatorDesktop";
import TopFixedNav from "../components/nav/TopFixedNav";
import useViewport from "../viewport/useViewport";
import { BannerWrapper, LayoutWrapper } from "./ThemeStyle";
import { setSideNavExpanded } from "../redux/sideNavExpansionSlice";
import SideDrawer from "../components/nav/SideDrawer";
import MyFooter from "../components/footer";
import { Colors } from "../config/Colors";

const DesktopLayout = ({ children }) => {
  const { height, isMobile, isTablet } = useViewport();
  const dispatch = useDispatch();
  // this state wether the sidenav is expanded or not
  const sideNavStatus = useSelector((state) => state.sideNavStatus);
  const banner = useSelector((state) => state.banner);
  // this function toggle the expansion state of side nav
  const toggleNavExpansion = (type) => {
    dispatch(
      setSideNavExpanded({
        isSideNavExpanded: !sideNavStatus.isSideNavExpanded,
        motionType: type,
      })
    );
  };
  return (
    <>
      <BannerWrapper
        bannerBg={banner.bgUrl}
        style={{
          background: banner.color === true && Colors.lightPurple,
        }}></BannerWrapper>
      <LayoutWrapper
        style={{ height: height }}
        isSideNavExpanded={sideNavStatus.isSideNavExpanded}
        isMobile={isMobile}>
        {/* this side nav bar will only be visible in desktop mode */}
        {!isTablet && (
          <div
            className="navigatorContainer"
            onMouseEnter={
              !sideNavStatus.isSideNavExpanded
                ? () => toggleNavExpansion("hover")
                : null
            }
            onMouseLeave={
              sideNavStatus.isSideNavExpanded &&
              sideNavStatus.motionType === "hover"
                ? () => toggleNavExpansion("clicked")
                : null
            }>
            <NavigatorDesktop />
          </div>
        )}
        {/* children container */}
        <div className="childrenContainer">
          <TopFixedNav />
          {isTablet && <SideDrawer />}
          {children}
          <MyFooter />
        </div>
      </LayoutWrapper>
    </>
  );
};

export default DesktopLayout;
