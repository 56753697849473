import { Icons } from "./IconsList";

export const NavOptions = [
  {
    title: "Dashboard",
    icon: Icons.StorefrontOutlinedIcon,
    linkTo: "/",
    folderName: "Home",
  },

  // {
  //   title: "User Profile",
  //   icon: Icons.ProfileIcon,
  //   linkTo: "/user/:userId",
  //   folderName: "Profile",
  // },
  {
    title: "Settings",
    icon: Icons.SettingsOutlinedIcon,
    linkTo: "/settings",
    folderName: "Pages",
  },

  {
    title: "Create Campaign",
    icon: Icons.AddIcon,
    linkTo: "/create/campaign",
    folderName: "Campaign",
  },
  {
    title: "All Campaigns",
    icon: Icons.CampaignIcon,
    linkTo: "/all/campaigns",
    folderName: "Campaign",
  },
  {
    title: "Unverified Campaigns",
    icon: Icons.UnderVerificationIcon,
    linkTo: "/all/unverified/campaigns",
    folderName: "Campaign",
  },
  {
    title: "Create User",
    icon: Icons.AddUserIcon,
    linkTo: "/create/user",
    folderName: "User",
  },
  {
    title: "All Users",
    icon: Icons.GroupIcon,
    linkTo: "/all/users",
    folderName: "Users",
  },
  {
    title: "Create Reels",
    icon: Icons.ReelsIcon,
    linkTo: "/create/reels",
    folderName: "Reels",
  },
  {
    title: "All Reels",
    icon: Icons.AllReels,
    linkTo: "/all/reels",
    folderName: "Reels",
  },
  {
    title: "Create Story",
    icon: Icons.StoryIcon,
    linkTo: "/create/story",
    folderName: "Story",
  },
  {
    title: "All Stories",
    icon: Icons.AllStoryIcon,
    linkTo: "/all/story",
    folderName: "Story",
  },
  {
    title: "Top Creator",
    icon: Icons.TopCreatorIcon,
    linkTo: "/top/creator",
    folderName: "Top Creator",
  },
  {
    title: "Unverified Insta",
    icon: Icons.InstaNav,
    linkTo: "/unverified/insta",
    folderName: "Instagram",
  },
];
