import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from "@mui/icons-material/Group";
import DoNotDisturbOnTotalSilenceIcon from "@mui/icons-material/DoNotDisturbOnTotalSilence";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  DoubleRightOutlined,
  FieldTimeOutlined,
  InfoCircleOutlined,
  InstagramOutlined,
  LinkOutlined,
  RightOutlined,
  SearchOutlined,
  UploadOutlined,
  YoutubeOutlined,
  SwapOutlined,
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  HistoryOutlined,
  NumberOutlined,
} from "@ant-design/icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import WindowIcon from "@mui/icons-material/Window";
import VerifiedIcon from "@mui/icons-material/Verified";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonIcon from "@mui/icons-material/Person";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PeopleIcon from "@mui/icons-material/People";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import WalletIcon from "@mui/icons-material/Wallet";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CampaignIcon from "@mui/icons-material/Campaign";
import DeleteIcon from "@mui/icons-material/Delete";
import FacebookIcon from "@mui/icons-material/Facebook";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CallMadeIcon from "@mui/icons-material/CallMade";
import LaunchIcon from "@mui/icons-material/Launch";
import DashboardIcon from "@mui/icons-material/Dashboard";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import CancelIcon from "@mui/icons-material/Cancel";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import TheatersIcon from "@mui/icons-material/Theaters";
import InstagramIcon from "@mui/icons-material/Instagram";
// Icon list
export const Icons = {
  logo: "https://demos.creative-tim.com/argon-dashboard-pro/assets/img/logo-ct-dark.png",
  bannerImage:
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg",
  EditIcon,
  ViewIcon: RemoveRedEyeIcon,
  AddIcon,
  GroupIcon,
  TotalCountIcon: DoNotDisturbOnTotalSilenceIcon,
  RupeeIcon: CurrencyRupeeIcon,
  ViewAllIcon: DoubleRightOutlined,
  LifestyleIcon: AccessibilityNewIcon,
  RightArrowIcon: RightOutlined,
  SettingsOutlinedIcon,
  StorefrontOutlinedIcon,
  ProfileIcon: PersonIcon,
  AppIcon: ViewInArIcon,
  MessageIcon: EmailIcon,
  InstagramIcon: InstagramOutlined,
  YouTubeIcon: YoutubeOutlined,
  VisibilityIcon,
  PeopleIcon,
  PersonAddAlt1Icon,
  TimeIcon: FieldTimeOutlined,
  AlarmClock: AccessAlarmIcon,
  WalletIcon,
  SearchIcon: SearchOutlined,
  InfoIcon,
  MoreVertIcon,
  DoneIcon,
  ClearIcon,
  ArrowUpwardIcon,
  ArrowDownwardIcon,
  CampaignIcon,
  DeleteIcon,
  FacebookIcon,
  PhotoCamera,
  OpenNewTabIcon: CallMadeIcon,
  LinkOutlined,
  OpenInNewTab: LaunchIcon,
  InfoIconOutlined: InfoCircleOutlined,
  UploadOutlined,
  AddUserIcon: PersonAddIcon,
  DashboardIcon,
  WindowIcon,
  SwapOutlined,
  UnderVerificationIcon: UnpublishedIcon,
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  VerifiedIcon,
  CancelIcon,
  ReelsIcon: TheatersIcon,
  AllReels: AutoAwesomeMotionIcon,
  StoryIcon: HistoryOutlined,
  AllStoryIcon: SlowMotionVideoIcon,
  RefreshIcon,
  TopCreatorIcon: NumberOutlined,
  InstaNav: InstagramIcon,
};
