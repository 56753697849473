import { Modal } from "antd";
import React from "react";
import { SubmittedValues } from "../../config/DummyData";
import { Icons } from "../../config/IconsList";
import styled from "styled-components";
import { Colors } from "../../config/Colors";
import CardHeading from "../headings/CardHeading";
import Linkify from "react-linkify";

const AssignmentSubmittedModal = ({ isModalOpen, handleCancel, handleOk }) => {
  return (
    <ModalWrapper
      open={isModalOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      footer={null}>
      <div className="mt2">
        <CardHeading title="Submitted notes and links" />
        <Linkify
          className="notes font"
          target="_blank"
          rel="noopener noreferrer">
          {SubmittedValues?.notes}
        </Linkify>
        {SubmittedValues?.links?.map((item, i) => {
          return (
            <div className="linkContainer" key={i}>
              <Icons.LinkOutlined className="linkIcon" />
              <a href={item} target="_blank" rel="noopener noreferrer">
                {item.substring(0, 22)}...{" "}
              </a>
              <Icons.OpenInNewTab className="openInNewTab" />
            </div>
          );
        })}
      </div>
    </ModalWrapper>
  );
};

export default AssignmentSubmittedModal;

const ModalWrapper = styled(Modal)`
  border: 2px solid ${Colors.textBlack};
  border-radius: 10px;
  padding: 0;
  .linkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.tagGreyBg};
    text-decoration: none;
  }
  .linkIcon {
    margin-right: 1rem;
  }
  .openInNewTab {
    margin-left: 1rem;
  }
`;
