import { Button, IconButton, Typography } from "@mui/material";
import {
  Col,
  Row,
  Upload,
  message,
  Image,
  Spin,
  Input,
  InputNumber,
} from "antd";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../../../../config/Colors";
import { Icons } from "../../../../config/IconsList";
import { ImageList } from "../../../../config/ImageList";
import { Instructions } from "../../../../config/Instruction";
import { LocalStorageIdentifier } from "../../../../config/LocalStorageInfo";
import { StepThreeSchema } from "../../../../config/Schemas";
import { storage } from "../../../../firebaseConfig";
import { uploadedImageUrl } from "../../../../redux/imageUploadSlice";
import { changeStepCount } from "../../../../redux/stepSlice";
import LabelInfo from "../../../inputLabel/LabelInfo";
import NormalLabel from "../../../inputLabel/NormalLabel";
import { ThirdStepWrapper } from "./Style";
// before uploading any image this function runs to check the format and size of image
const beforeUpload = (file) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/webp" ||
    file.type === "image/avif";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/WEBP/AVIF file!");
  }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    message.error("Image must smaller than 10MB!");
  }
  return isJpgOrPng && isLt10M;
};

const { Dragger } = Upload;
//   initial values
let initialValues = {
  campaignBanner: "",
  referenceImage: [],
  productReference: [],
  referenceVideo: [],
};
// =================================================================
// =================================================================
// =================================================================
// =================================================================
// default function with jsx
const StepThreeForm = () => {
  const dispatch = useDispatch();
  // useState
  const stepper = useSelector((state) => state.stepper);
  const [imageUrl, setImageUrl] = useState("");
  const [preFilledData, setPreFilledData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [loadingMulti, setLoadingMulti] = useState(false);
  const [loadingBasic, setLoadingBasic] = useState(false);
  const [images, setImages] = useState([]);
  const [productRefValue, setProductRefValue] = useState({
    name: "",
    price: "",
  });
  const [imageValue, setImageValue] = useState({
    imageUrl: "",
    fileName: "",
  });
  const [videoUrl, setVideoUrl] = useState("");

  // ===========================************==================================
  // ===========================useEffect=====================================
  // ===========================*************=================================
  useEffect(() => {
    let savedDataOfStepThree = localStorage.getItem(
      LocalStorageIdentifier.stepThreeData
    );
    if (savedDataOfStepThree) {
      setPreFilledData(JSON.parse(savedDataOfStepThree));
      setImages(JSON.parse(savedDataOfStepThree)?.referenceImage);
    } else {
      setPreFilledData(initialValues);
      console.log("LOCAL STORAGE NOT FOUND");
    }
  }, [stepper.currentStep]);
  useEffect(() => {
    handleChange({
      target: {
        name: "referenceImage",
        value: images,
      },
    });
  }, [images.length]);

  // ===========================************==================================
  // ===========================useFormik=====================================
  // ===========================*************=================================
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: preFilledData,
    validationSchema: StepThreeSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(
        changeStepCount({
          data: "abc",
          currentStep: stepper?.currentStep + 1,
        })
      );
      // retrieve the data until the current step
      let savedData = JSON.parse(
        localStorage.getItem(LocalStorageIdentifier.createCampaignData)
      );

      // store data in localStorage by combining the previous data and this data
      localStorage.setItem(
        LocalStorageIdentifier.createCampaignData,
        JSON.stringify({ ...savedData, ...values })
      );
      // store all the data of this particular step under a different name
      localStorage.setItem(
        LocalStorageIdentifier.stepThreeData,
        JSON.stringify(values)
      );
      // store the current step in localstorage which will help to know that till what step is completed
      localStorage.setItem(
        LocalStorageIdentifier.stepCompleted,
        JSON.stringify(4)
      );
    },
  });

  // ===========================************==================================
  // ===========================functions=====================================
  // ===========================*************=================================
  // handle multiple upload with firebase and antd Dragger
  const handleMultipleUpload = (value) => {
    console.log("MULTIPLE UPLOAD", value);
    console.log("type of==>", typeof value);
    const { file } = value;
    // Loop through the array of files

    console.log("FILE SELECTED==>", file);
    const storageRef = ref(storage, `/files/${file.name}-${Date.now()}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setLoadingMulti(true);
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        ); // update progress
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);

          // Add the download URL to the images array
          setImages((prevImages) => [...prevImages, url]);

          setLoadingMulti(false);
        });
      }
    );
  };

  // Handle file upload event and upload image to firebase storage and retrieve the link of uploaded image
  function handleImageUploadToFirebase(value) {
    const { file } = value;
    console.log("FILE SELECTED==>", value);
    const storageRef = ref(storage, `/files/${file.name}-${Date.now()}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setLoading(true);
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        ); // update progress
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          handleChange({
            target: {
              name: "campaignBanner",
              value: url,
            },
          });
          dispatch(
            uploadedImageUrl({
              imageUrl: url,
              type: "banner",
            })
          );
          setImageUrl(url);
          setLoading(false);
        });
      }
    );
  }

  // Handle file upload event and upload image to firebase storage and retrieve the link of uploaded image
  function uploadImageBasicInput(event) {
    console.log("UPLOAD FILE", event.target.files);
    const storageRef = ref(
      storage,
      `/files/${event.target.files[0].name}-${Date.now()}`
    ); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, event.target.files[0]);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        ); // update progress
        setLoadingBasic(true);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          setLoadingBasic(false);
          setImageValue({
            imageUrl: url,
            fileName: event.target.files[0].name,
          });
        });
      }
    );
  }

  // handle input change for input boxes used in product reference
  const handleInputChange = (e) => {
    setProductRefValue({ ...productRefValue, [e.target.name]: e.target.value });
  };

  // function which runs when someone clicks on Add button
  const handleAddButton = () => {
    const myValues = { ...imageValue, ...productRefValue };
    handleChange({
      target: {
        name: "productReference",
        value: [...values?.productReference, myValues],
      },
    });
    setImageValue({
      imageUrl: "",
      fileName: "",
    });
    setProductRefValue({
      name: "",
      price: "",
    });
  };
  // function which runs when someone clicks on Remove button of Product Reference
  const handleRemoveButton = (url) => {
    const newArray = values?.productReference?.filter(
      (x) => x.imageUrl !== url
    );
    handleChange({
      target: {
        name: "productReference",
        value: newArray,
      },
    });
  };
  // function which runs when someone clicks on Add button of Reference Video
  const handleAddVideoButton = () => {
    handleChange({
      target: {
        name: "referenceVideo",
        value: [...values?.referenceVideo, videoUrl],
      },
    });
    setVideoUrl("");
  };
  // function which runs when someone clicks on Remove button of Reference Video
  const handleRemoveVideoButton = (url) => {
    const newArray = values?.referenceVideo?.filter((x) => x !== url);
    handleChange({
      target: {
        name: "referenceVideo",
        value: newArray,
      },
    });
  };
  // check wether a given string is an URL or not
  function isValidURL(str) {
    try {
      new URL(str);
      return true;
    } catch (err) {
      return false;
    }
  }
  // function runs when Next button is clicked
  const handleNextButton = (e) => {
    e.preventDefault();
    handleSubmit();
  };
  // function runs when Next button is clicked
  const handlePrevButton = (e) => {
    e.preventDefault();
    dispatch(
      changeStepCount({
        data: "abc",
        currentStep: stepper?.currentStep - 1,
      })
    );
    // store all the data of this particular step under a different name
    localStorage.setItem(
      LocalStorageIdentifier.stepThreeData,
      JSON.stringify(values)
    );
  };
  // ======
  // jsx
  // ======
  return (
    <ThirdStepWrapper>
      {/* {JSON.stringify(values, 2, 4)} */}

      <Row gutter={[16, 24]}>
        <Col xs={24} md={12} lg={12} style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <NormalLabel title="Campaign Banner" />
            {values?.campaignBanner === "" || !values?.campaignBanner ? (
              <Dragger
                showUploadList={false}
                customRequest={handleImageUploadToFirebase}
                beforeUpload={beforeUpload}
                style={{
                  background: "transparent",
                  flex: 1,
                }}
              >
                {" "}
                <p className="ant-upload-drag-icon">
                  {loading ? (
                    <Spin />
                  ) : (
                    <img
                      src={ImageList.UploadFileImageIcon}
                      alt="imageUpload"
                      className="imageUploadIcon"
                    />
                  )}
                </p>
                <Typography className="font uploadHeading">
                  Click to upload{" "}
                  <span style={{ fontWeight: 300 }}>or drag & drop</span>
                </Typography>
                <Typography className="font uploadSubHeading">
                  Preferred size (1600X1100) Maximum 10MB
                  <br />
                  Upload only JPG or PNG images
                </Typography>{" "}
              </Dragger>
            ) : (
              <Dragger
                customRequest={handleImageUploadToFirebase}
                beforeUpload={beforeUpload}
                showUploadList={false}
                className="draggerWithImage"
                style={{
                  background: "transparent",
                }}
              >
                {loading ? (
                  <Spin />
                ) : (
                  <img
                    style={{ width: "100%" }}
                    className="uploadedImage"
                    src={values?.campaignBanner}
                    alt="uploadedImage"
                  />
                )}
              </Dragger>
            )}
            {errors.campaignBanner && touched.campaignBanner ? (
              <p className="font" style={{ color: Colors.alertRed }}>
                {errors.campaignBanner}
              </p>
            ) : null}
          </div>
        </Col>
        <Col xs={24} md={12} lg={12} style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <div className="flexBetween">
              <LabelInfo
                identifier="referenceImage"
                title="Reference Image"
                info={Instructions.ReferenceImage}
              />
              <IconButton onClick={() => setImages([])}>
                <Icons.DeleteIcon />
              </IconButton>
            </div>
            {values?.referenceImage?.length === 0 && images.length === 0 ? (
              <Dragger
                maxCount={6}
                multiple={true}
                showUploadList={false}
                customRequest={handleMultipleUpload}
                beforeUpload={beforeUpload}
                style={{ background: "transparent" }}
              >
                <p className="ant-upload-drag-icon">
                  <img
                    src={ImageList.UploadFileImageIcon}
                    alt="imageUpload"
                    className="imageUploadIcon"
                  />
                </p>
                <Typography className="font uploadHeading">
                  Click to upload{" "}
                  <span style={{ fontWeight: 300 }}>or drag & drop</span>
                </Typography>
                <Typography className="font uploadSubHeading">
                  Preferred size (1600X1100) Maximum 10MB
                </Typography>
              </Dragger>
            ) : (
              <div
                className="imagesListContainer"
                style={{ alignItems: images?.length > 3 && "center" }}
              >
                {images?.slice(0, 6).map((item, i) => {
                  return (
                    <Image
                      src={item}
                      key={i}
                      alt="image"
                      className="uploadedImageSmall"
                    />
                  );
                })}
                {loadingMulti ? <Spin /> : null}
                {images?.length > 5 ? null : (
                  <Upload
                    maxCount={6 - images?.length}
                    className="smallUploader"
                    multiple={true}
                    showUploadList={false}
                    customRequest={handleMultipleUpload}
                    beforeUpload={beforeUpload}
                  >
                    <img
                      src={ImageList.UploadFileImageIcon}
                      className="smallUploadImage"
                      alt="upload"
                    />
                    <div className="uploadTextContainer">
                      <Typography className="font uploadText">
                        Upload
                      </Typography>
                    </div>
                  </Upload>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
      {/*===========================************==================================
       ===================== UPLOAD PRODUCT REFERENCE==========================
       ===========================*************================================= */}
      <div className="mt1">
        <LabelInfo
          title="Add Product/ Service media"
          identifier="productReference"
          info={Instructions.ReferenceProduct}
        />
      </div>
      {/* product service and media */}
      {/* ========================================================================== */}
      {/* =========================LIST OF ADDED PRODUCT REFERENCE================== */}
      {/* ========================================================================== */}
      {values?.productReference?.length > 0 &&
        values?.productReference?.map((item, i) => {
          return (
            <Row gutter={[16, 24]} key={i} className="mt1">
              <Col xs={12} md={12} lg={12}>
                <div className="UploadFileContainer">
                  <Image
                    src={item.imageUrl}
                    alt="upload product image"
                    className="productUploadedImageAfter"
                  />

                  <div>
                    <Typography
                      variant="caption"
                      className="fileNameBefore font"
                    >
                      {item.fileName.substring(0, 60)}
                    </Typography>
                  </div>
                </div>
              </Col>
              <Col xs={4} md={4} lg={4}>
                <Input
                  readOnly
                  placeholder="Name"
                  size="large"
                  name="name"
                  value={item.name}
                />
              </Col>
              <Col xs={4} md={4} lg={4}>
                <InputNumber
                  readOnly
                  style={{ width: "100%" }}
                  min={0}
                  placeholder="Price"
                  size="large"
                  name="price"
                  value={item.price}
                />
              </Col>
              <Col xs={4} md={4} lg={4}>
                <Button
                  onClick={() => handleRemoveButton(item.imageUrl)}
                  style={{ backgroundColor: Colors.greyD9 }}
                  variant="contained"
                  fullWidth
                  className="addButton"
                  disableElevation
                >
                  Remove
                </Button>
              </Col>
            </Row>
          );
        })}
      {/* ========================================================================== */}
      {/* =========================ADD NEW PRODUCT REFERENCE======================== */}
      {/* ========================================================================== */}
      <Row gutter={[16, 24]} className="mt1">
        <Col xs={12} md={12} lg={12}>
          <div className="UploadFileContainer">
            <div className="productUploadedImageContainer flexCenter">
              <img
                src={
                  imageValue?.imageUrl === ""
                    ? ImageList.UploadFileImageIcon
                    : imageValue?.imageUrl
                }
                alt="upload product"
                className="productUploadedImage"
              />
            </div>

            {imageValue?.imageUrl === "" ? (
              <Button
                variant="text"
                className="uploadTextButton"
                aria-label="upload picture"
                component="label"
              >
                Click to Upload
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={uploadImageBasicInput}
                />
              </Button>
            ) : (
              <div>
                <Typography variant="caption" className="fileNameBefore font">
                  {imageValue.fileName.substring(0, 60)}
                </Typography>
              </div>
            )}
            {loadingBasic ? <Spin /> : null}
          </div>
        </Col>
        {/* name input */}
        <Col xs={4} md={4} lg={4}>
          <Input
            placeholder="Name"
            size="large"
            onChange={handleInputChange}
            name="name"
            value={productRefValue.name}
          />
        </Col>
        {/* price input */}
        <Col xs={4} md={4} lg={4}>
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            placeholder="Price"
            size="large"
            onChange={(number) =>
              setProductRefValue({ ...productRefValue, price: number })
            }
            name="price"
            value={productRefValue.price}
          />
        </Col>
        {/* add button */}
        <Col xs={4} md={4} lg={4}>
          <Button
            disabled={
              imageValue.fileName === "" ||
              imageValue.imageUrl === "" ||
              productRefValue.name === "" ||
              productRefValue.price === "" ||
              productRefValue.price === null
            }
            sx={{
              "&:disabled": {
                backgroundColor: Colors.lightPurple,
                color: Colors.white,
                opacity: 0.5,
              },
            }}
            onClick={handleAddButton}
            variant="contained"
            fullWidth
            className="addButton"
            disableElevation
          >
            Add
          </Button>
        </Col>
      </Row>
      {/* ========================================================================== */}
      {/* =========================LIST OF ADDED REFERENCE VIDEO==================== */}
      {/* ========================================================================== */}
      <div className="mt1">
        <LabelInfo
          title="Reference Video Example (Link) "
          identifier="productReferenceVideo"
          info={Instructions.ReferenceVideo}
        />
      </div>
      {values?.referenceVideo?.length > 0 &&
        values?.referenceVideo?.map((item, i) => {
          return (
            <Row gutter={[16, 24]} key={i} className="mt1">
              <Col xs={20} md={20} lg={20}>
                <Input
                  readOnly
                  prefix={
                    <img
                      src={ImageList.UploadVideoImageIcon}
                      alt="upload video"
                      className="productUploadedImage"
                    />
                  }
                  size="large"
                  placeholder="Paste your video URL..."
                  value={item}
                />
              </Col>

              <Col xs={4} md={4} lg={4}>
                <Button
                  onClick={() => handleRemoveVideoButton(item)}
                  style={{ backgroundColor: Colors.greyD9 }}
                  variant="contained"
                  fullWidth
                  className="addButton"
                  disableElevation
                >
                  Remove
                </Button>
              </Col>
            </Row>
          );
        })}
      {/* ========================================================================== */}
      {/* =========================ADD NEW REFERENCE VIDEO========================== */}
      {/* ========================================================================== */}
      <Row gutter={[16, 24]} className="mt1">
        <Col xs={20} md={20} lg={20}>
          <Input
            prefix={
              <img
                src={ImageList.UploadVideoImageIcon}
                alt="upload video"
                className="productUploadedImage"
              />
            }
            size="large"
            placeholder="Paste your video URL..."
            onChange={(e) => setVideoUrl(e.target.value)}
            value={videoUrl}
          />
        </Col>

        {/* add button */}
        <Col xs={4} md={4} lg={4}>
          <Button
            disabled={videoUrl === "" || !isValidURL(videoUrl)}
            sx={{
              "&:disabled": {
                backgroundColor: Colors.lightPurple,
                color: Colors.white,
                opacity: 0.5,
              },
            }}
            onClick={handleAddVideoButton}
            variant="contained"
            fullWidth
            className="addButton"
            disableElevation
          >
            Add
          </Button>
        </Col>
      </Row>
      {/* =================================== */}
      {/* next and previous buttons */}
      {/* =================================== */}
      <Row gutter={[16, 24]} className="mt4">
        <Col xs={12} md={12} lg={12}>
          <Button
            fullWidth
            variant="outlined"
            className="prevButton"
            onClick={handlePrevButton}
          >
            Previous
          </Button>
        </Col>
        <Col xs={12} md={12} lg={12}>
          <Button
            fullWidth
            variant="contained"
            className="nextButton"
            onClick={handleNextButton}
          >
            Next
          </Button>
        </Col>
      </Row>
    </ThirdStepWrapper>
  );
};

export default StepThreeForm;
