import HandshakeImageIcon from "../assets/Handshake.png";
import MoneyBagImageIcon from "../assets/MoneyBag.png";
import TagsImageIcon from "../assets/Tags.png";
import InstagramImageIcon from "../assets/Instagram.png";
import YouTubeImageIcon from "../assets/YouTube.png";
import FacebookImageIcon from "../assets/Facebook.png";
import GoldenTickImageIcon from "../assets/GoldenTick.png";
import CalenderImageIcon from "../assets/Calender.png";
import RunningCampaignImageIcon from "../assets/RunningCampaign.png";
import UserImageIcon from "../assets/User.png";
import AcceptImageIcon from "../assets/Accept.png";
import RejectImageIcon from "../assets/Reject.png";
import AcceptFilledImageIcon from "../assets/AcceptFilled.png";
import EyeImageIcon from "../assets/Eye.png";
import ReshootImageIcon from "../assets/Reset.png";
import InstaReelImageIcon from "../assets/InstaReel.png";
import HumanHeadImageIcon from "../assets/HumanHead.png";
import LoudspeakerImageIcon from "../assets/Loudspeaker.png";
import YoutubeShortsImageIcon from "../assets/YoutubeShorts.png";
import MoneyBagGreenImageIcon from "../assets/MoneyBagGreen.png";
import TagBlackImageIcon from "../assets/TagBlack.png";
import UploadFileImageIcon from "../assets/FileUpload.png";
import UploadVideoImageIcon from "../assets/VideoUpload.png";
import LoginImageBg from "../assets/loginBg.png";
import LoadingImageBg from "../assets/loading_bg.jpg.avif";
import Cover_Photo from "../assets/profile_bg.jpg";
import DefaultProfilePicture from "../assets/default_profile_image.webp";
import Location_On_Profile from "../assets/Location_on_profile.png";
import Age_On_Profile from "../assets/age_on_profile.png";
import Barter_On_Profile from "../assets/barter_on_profile.png";
import Categories_On_Profile from "../assets/category_on_profile.png";
import Heic_Image_Placeholder from "../assets/ClickToView.jpg";

export const ImageList = {
  InstagramImageUrl:
    "https://res.cloudinary.com/axborapp/image/upload/v1673517654/Instagram_oywebj.png",
  FacebookImageUrl:
    "https://res.cloudinary.com/axborapp/image/upload/v1673517654/Facebook_vf0rv9.png",
  YouTubeImageUrl:
    "https://res.cloudinary.com/axborapp/image/upload/v1673517654/YouTube_a4nvob.png",
  InstagramReelImageUrl:
    "https://res.cloudinary.com/axborapp/image/upload/v1673535103/image_24_irv52i.png",
  YouTubeShortsImageUrl:
    "https://res.cloudinary.com/axborapp/image/upload/v1673535103/image_23_ve9kmd.png",
  HandshakeImageIcon,
  MoneyBagImageIcon,
  TagsImageIcon,
  InstagramImageIcon,
  YouTubeImageIcon,
  FacebookImageIcon,
  GoldenTickImageIcon,
  CalenderImageIcon,
  RunningCampaignImageIcon,
  UserImageIcon,
  HumanHeadImageIcon,
  AcceptImageIcon,
  RejectImageIcon,
  ReshootImageIcon,
  AcceptFilledImageIcon,
  EyeImageIcon,
  InstaReelImageIcon,
  YoutubeShortsImageIcon,
  MoneyBagGreenImageIcon,
  LoudspeakerImageIcon,
  TagBlackImageIcon,
  UploadFileImageIcon,
  UploadVideoImageIcon,
  LoginImageBg,
  DefaultProfilePicture,
  LoadingImageBg,
  Cover_Photo,
  Location_On_Profile,
  Age_On_Profile,
  Categories_On_Profile,
  Barter_On_Profile,
  Heic_Image_Placeholder,
};
