import { Col, Row } from "antd";
import React from "react";
import { TableWrapper } from "../../pages/home/HomeStyles";
import MessageArea from "./MessageArea";
import { MyMessageWrapper } from "./MessageArea/Style";
import ChatRoom from "./SingleUserChatRoom";

const MyMessages = () => {
  return (
    <MyMessageWrapper className="mt3">
      <Row gutter={[0, 24]} style={{ height: "90vh" }}>
        <Col
          xs={0}
          md={5}
          lg={8}
          style={{
            display: "flex",
          }}>
          <MessageArea />
        </Col>
        <Col xs={0} md={19} lg={16} style={{ display: "flex", height: "90vh" }}>
          <ChatRoom />
        </Col>
      </Row>
    </MyMessageWrapper>
  );
};

export default MyMessages;
