import { Divider, IconButton, Typography } from "@mui/material";
import { Col } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Colors } from "../../../config/Colors";
import { Chats, LargeText } from "../../../config/DummyData";
import { Icons } from "../../../config/IconsList";
import MessageText from "../messageText";
import MessageAreaNav from "../Nav/MessageAreaNav";

import { ChatRoomWrapper } from "./Style";

const ChatRoom = () => {
  const chatUser = useSelector((state) => state.chatUser);
  const user = useSelector((state) => state.user);
  const [divHeight, setDivHeight] = useState("");
  const [messages, setMessages] = useState([]);
  const divRef = useRef(null);
  const currentUserId = 1;
  useEffect(() => {
    const div = divRef.current;
    if (div) {
      const divHeight = div.offsetHeight;
      console.log(`The height of the div is ${divHeight}px.`);
      setDivHeight(`${divHeight}px`);
    }
  }, [divRef]);

  useEffect(() => {
    const getChats = () => {
      let chats = Chats.filter((item) => item.uid === chatUser?.uid);
      setMessages(chats[0]?.messages);
    };
    getChats();
  }, [chatUser.uid]);

  return (
    <ChatRoomWrapper>
      {/* nav */}

      <MessageAreaNav user={chatUser} isRoom={true} />

      {/* area where chat is displayed */}

      <div className="chatArea">
        {messages?.map((message, i) => {
          return (
            <MessageText
              currentUserId={currentUserId}
              message={message}
              key={i}
            />
          );
        })}
      </div>

      {/* type message here */}

      <div className="typeMessageBox flexBetween">
        <input className="inputMessage" placeholder="Reply..." />
        <IconButton className="sendIcon">
          <Icons.RightArrowIcon
            style={{ color: Colors.white, fontWeight: "300", fontSize: 20 }}
          />
        </IconButton>
      </div>
    </ChatRoomWrapper>
  );
};

export default ChatRoom;
