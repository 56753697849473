import React, { useEffect, useState } from "react";
import { Row } from "antd";
import { useSelector } from "react-redux";
import StepFourForm from "../../../components/forms/EditCampaign/StepFour";
import StepOneForm from "../../../components/forms/EditCampaign/StepOne";
import StepThreeForm from "../../../components/forms/EditCampaign/StepThree";
import StepTwoForm from "../../../components/forms/EditCampaign/StepTwo";
import StepZero from "../../../components/forms/EditCampaign/StepZero";
import CardHeading from "../../../components/headings/CardHeading";
import AntSteps from "../../../components/stepper/AntSteps";
import { ImageList } from "../../../config/ImageList";
import DesktopLayout from "../../../theme/DesktopLayout";
import useViewport from "../../../viewport/useViewport";
import { LocalStorageIdentifier } from "../../../config/LocalStorageInfo";
import { EditWrapper } from "./EditStyle";
import { useParams } from "react-router-dom";
import { getSingleCampaignDetailAPI } from "../../../functions/campaign";

const EditCampaign = () => {
  const { currentStep } = useSelector((state) => state.stepper);
  const user = useSelector((state) => state.user);
  const { isMidrange, isTablet } = useViewport();
  const { campaignId } = useParams();
  const [campaignData, setCampaignData] = useState({});

  useEffect(() => {
    // make api all and prefill the localstorage values
    if (user?.token !== "") {
      getSingleCampaignDetailAPI(campaignId, user?.token)
        .then((res) => {
          console.log("PERIOD", res.data?.campaign);
          setCampaignData(res.data?.campaign);
          let myCampaign = res.data?.campaign;
          // step zero data
          let barndValue = {
            label: myCampaign.brand?.name,
            value: myCampaign.brand?._id,
            url: myCampaign?.brand?.profilePicture?.url,
          };
          // step one data
          let stepOneData = {
            campaignName: myCampaign?.campaignName,
            campaignPeriod: {
              startDate: myCampaign?.campaignPeriod?.startDate,
              endDate: res.data?.campaign?.campaignPeriod?.endDate,
            },
            campaignPlatform: myCampaign?.campaignPlatform,
            deliverableType: myCampaign?.deliverableType,
            dos: myCampaign?.dos,
            donts: myCampaign?.donts,
          };
          // step two data
          let stepTwoData = {
            campaignCategories: [],
            age: myCampaign?.age,
            isBarter: myCampaign?.isBarter,
            influencerBudget: myCampaign?.influencerBudget,
            followersRange: myCampaign?.followersRange,
            gender: myCampaign?.gender,
            influencerRequired: myCampaign?.influencerRequired,
          };
          // step three data
          let stepThreeData = {
            campaignBanner: myCampaign?.campaignBanner,
            referenceImage: myCampaign?.referenceImage,
            productReference: myCampaign?.productReference,
            referenceVideo: myCampaign?.referenceVideo,
          };
          // Create a new object that excludes the _id key-value pair
          const newObject = { ...myCampaign };
          delete newObject._id;
          // set local storage values
          localStorage.setItem(
            LocalStorageIdentifier.editCampaignData,
            JSON.stringify({ ...newObject })
          );
          localStorage.setItem(
            LocalStorageIdentifier.editStepZeroData,
            JSON.stringify({ ...barndValue })
          );
          localStorage.setItem(
            LocalStorageIdentifier.editStepOneData,
            JSON.stringify({ ...stepOneData })
          );
          localStorage.setItem(
            LocalStorageIdentifier.editStepTwoData,
            JSON.stringify({ ...stepTwoData })
          );
          localStorage.setItem(
            LocalStorageIdentifier.editStepThreeData,
            JSON.stringify({ ...stepThreeData })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [campaignId, user]);

  return (
    <DesktopLayout>
      <EditWrapper isMidrange={isMidrange} isTablet={isTablet}>
        <AntSteps />
        <div className="mt2 formContainer">
          {currentStep === 0 ? (
            <div style={{ width: "100%" }}>
              <div className="headingContainer">
                <img
                  className="loudspeakerIcon"
                  src={ImageList.LoudspeakerImageIcon}
                  alt="speaker"
                />{" "}
                <CardHeading title="Edit Campaign Info" weight="700" />
              </div>
              <Row gutter={[16, 24]} className="mt4">
                <StepZero />
              </Row>
            </div>
          ) : currentStep === 1 ? (
            <>
              {" "}
              <div className="headingContainer">
                <img
                  className="loudspeakerIcon"
                  src={ImageList.LoudspeakerImageIcon}
                  alt="speaker"
                />{" "}
                <CardHeading title="Edit Campaign Info" weight="700" />
              </div>
              <StepOneForm endDate={campaignData?.campaignPeriod?.endDate} />{" "}
            </>
          ) : currentStep === 2 ? (
            <>
              {" "}
              <div className="headingContainer">
                <CardHeading title="Edit Influencer Info" weight="700" />
              </div>
              <StepTwoForm />{" "}
            </>
          ) : currentStep === 3 ? (
            <>
              {" "}
              <div className="headingContainer">
                <CardHeading title="Edit Campaign Media" weight="700" />
              </div>
              <StepThreeForm />{" "}
            </>
          ) : (
            <>
              {" "}
              <div className="headingContainer">
                <CardHeading
                  title="Publish Your Edited Campaign"
                  weight="700"
                />
              </div>
              <StepFourForm campaignId={campaignId} />{" "}
            </>
          )}
        </div>
      </EditWrapper>
    </DesktopLayout>
  );
};

export default EditCampaign;
