import { Button, Typography } from "@mui/material";
import { Col, InputNumber, Row, Select, Switch } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "../../../../config/IconsList";
import { ImageList } from "../../../../config/ImageList";
import { Instructions } from "../../../../config/Instruction";
import { LocalStorageIdentifier } from "../../../../config/LocalStorageInfo";
import { StepTwoSchema } from "../../../../config/Schemas";
import { CategoryList, genderOptions } from "../../../../config/Values";
import { changeStepCount } from "../../../../redux/stepSlice";
import LabelInfo from "../../../inputLabel/LabelInfo";
import NormalLabel from "../../../inputLabel/NormalLabel";
import { StepTwoWrapper } from "./Style";
const { Option } = Select;
//   initial values
let initialValues = {
  campaignCategories: [],
  age: {
    min: 14,
    max: 0,
  },
  isBarter: false,
  influencerBudget: {
    min: null,
    max: null,
  },
  followersRange: {
    min: null,
    max: null,
  },
  gender: "",
  influencerRequired: null,
};
const StepTwoForm = () => {
  const dispatch = useDispatch();
  const stepper = useSelector((state) => state.stepper);
  // useState
  const [preFilledData, setPreFilledData] = useState(initialValues);
  let myValue =
    localStorage.getItem(LocalStorageIdentifier.createCampaignData) !== "";

  // ===========================************==================================
  // ===========================useFormik=====================================
  // ===========================*************=================================
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: preFilledData,
      validationSchema: StepTwoSchema,
      validateOnBlur: true,
      validateOnChange: true,
      onSubmit: (values, { setSubmitting }) => {
        console.log("NEXT BUTTON CLICKED FROM HANDLE SUBMIT!");
        dispatch(
          changeStepCount({
            data: "abc",
            currentStep: stepper?.currentStep + 1,
          })
        );
        let savedData =
          myValue &&
          JSON.parse(
            localStorage.getItem(LocalStorageIdentifier.createCampaignData)
          );
        localStorage.setItem(
          LocalStorageIdentifier.createCampaignData,
          JSON.stringify({ ...savedData, ...values })
        );
        // store all the data of this particular step under a different name
        localStorage.setItem(
          LocalStorageIdentifier.stepTwoData,
          JSON.stringify(values)
        );
        // store the current step in localstorage which will help to know that till what step is completed
        localStorage.setItem(
          LocalStorageIdentifier.stepCompleted,
          JSON.stringify(3)
        );
      },
    });
  // ===========================************==================================
  // ===========================useEffect=====================================
  // ===========================*************=================================
  useEffect(() => {
    let savedDataOfStepTwo = localStorage.getItem(
      LocalStorageIdentifier.stepTwoData
    );
    if (savedDataOfStepTwo !== "") {
      setPreFilledData(JSON.parse(savedDataOfStepTwo));
    } else {
      setPreFilledData(initialValues);
      console.log("LOCAL STORAGE NOT FOUND");
    }
  }, [stepper.currentStep]);
  // whenever isBarter is selected, influencer budget will move to zero
  useEffect(() => {
    handleChange({ target: { name: "influencerBudget.min", value: null } });
    handleChange({ target: { name: "influencerBudget.max", value: null } });
  }, [values?.isBarter]);

  // ===========================************==================================
  // ===========================functions=====================================
  // ===========================*************=================================

  // function runs when Next button is clicked
  const handleNextButton = (e) => {
    e.preventDefault();
    handleSubmit();
  };
  // function runs when Next button is clicked
  const handlePrevButton = (e) => {
    e.preventDefault();
    // store all the data of this particular step under a different name
    localStorage.setItem(
      LocalStorageIdentifier.stepTwoData,
      JSON.stringify(values)
    );
    // save in redux store
    dispatch(
      changeStepCount({
        data: "abc",
        currentStep: stepper?.currentStep - 1,
      })
    );
  };

  // handle category change by pushing the selected category in an array
  const handleCategoryChange = (e) => {
    handleChange({
      target: {
        name: "campaignCategories",
        value: e,
      },
    });
  };
  const handleGenderChange = (e) => {
    handleChange({
      target: {
        name: "gender",
        value: e,
      },
    });
  };

  // handle change of all input of numbers by passing the target name and value
  const handleNumberChange = (amount, identifier) => {
    if (isNaN(amount)) {
      return;
    }
    handleChange({
      target: { name: identifier, value: amount },
    });
  };

  // barter switch handle
  const handleBarterSwitch = (status) => {
    handleChange({
      target: { name: "isBarter", value: status },
    });
  };

  // ===========================************==================================
  // ===============================jsx=====================================
  // ===========================*************=================================
  return (
    <StepTwoWrapper>
      {/* {JSON.stringify(stepper?.currentStep)} */}
      <form onSubmit={handleSubmit}>
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12} lg={12}>
            <div>
              <NormalLabel title="Category" identifier="categories" />
              <Select
                value={
                  values?.campaignCategories !== [] &&
                  values?.campaignCategories
                }
                status={
                  touched.campaignCategories && errors.campaignCategories
                    ? "error"
                    : null
                }
                id="categories"
                onChange={handleCategoryChange}
                showArrow
                style={{ width: "100%" }}
                allowClear
                className="categorySelect"
                suffixIcon={
                  <img
                    src={ImageList.TagBlackImageIcon}
                    alt="icon"
                    className="tagsIcon"
                  />
                }
                mode="multiple"
                maxTagCount="responsive"
                size="large"
                placeholder="Chose Category"
              >
                {CategoryList?.map((item, i) => {
                  return (
                    <Option key={i} value={item.label}>
                      {" "}
                      {item.label}{" "}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="flexBetween">
              {/* age of influencer which is desired */}
              <div style={{ width: "45%" }}>
                <NormalLabel title="Select Age" identifier="age" />
                <div className="ageInputContainer">
                  {/* min age */}
                  <div className="dateContainer">
                    <InputNumber
                      status={
                        touched.age?.min && errors.age?.min ? "error" : null
                      }
                      name="age.min"
                      value={values?.age.min}
                      min={14}
                      size="large"
                      placeholder="XX"
                      className="ageInput"
                      onChange={(amount) =>
                        handleNumberChange(amount, "age.min")
                      }
                      onBlur={handleBlur}
                    />
                    {touched.age?.min && errors.age?.min ? (
                      <div className="alertMessage">{errors.age?.min}</div>
                    ) : null}
                  </div>
                  <Typography className="font ageSeparatorText">To</Typography>
                  {/* max age */}
                  <div className="dateContainer">
                    <InputNumber
                      status={
                        touched.age?.max && errors.age?.max ? "error" : null
                      }
                      onChange={(amount) =>
                        handleNumberChange(amount, "age.max")
                      }
                      name="age.max"
                      value={values?.age.max}
                      min={values?.age.min + 1}
                      disabled={
                        values?.age.min === "" || values?.age.min === null
                      }
                      size="large"
                      placeholder="XX"
                      className="ageInput"
                    />
                  </div>
                </div>
              </div>
              {/* select gender */}
              <div
                className="dateContainer"
                style={{ width: "100%", marginLeft: 20 }}
              >
                <NormalLabel title="Gender" identifier="gender" />

                <Select
                  id="gender"
                  value={values?.gender !== "" && values?.gender}
                  status={touched.gender && errors.gender ? "error" : null}
                  size="large"
                  name="gender"
                  placeholder="Select Gender"
                  style={{ width: "100%" }}
                  onChange={handleGenderChange}
                >
                  {genderOptions?.map((item, i) => {
                    return (
                      <Option key={i} value={item?.name}>
                        {" "}
                        {item.name}{" "}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </Col>
        </Row>
        {/* ===========================************====================================
         ================================2ndROW=====================================
          ===========================*************=================================== */}
        <Row gutter={[16, 24]} className="mt2">
          <Col xs={24} md={12} lg={12}>
            <div className="flexBetween">
              {/* switch for deciding wether the campaign is Barter type or not */}
              <div
                className="dateContainer"
                style={{ width: "35%", marginRight: 20 }}
              >
                <LabelInfo
                  title="Barter"
                  info={Instructions.Barter}
                  identifier="barter"
                />
                <div className="barterContainer ant-input-affix-wrapper css-dev-only-do-not-override-26rdvq">
                  <Typography variant="body2" className="font barterText">
                    Barter?
                  </Typography>
                  <Switch
                    onChange={handleBarterSwitch}
                    checked={values?.isBarter}
                  />
                </div>
              </div>
              {/* minimum and maximum budget of influencer is decided */}
              <div style={{ flex: 1 }}>
                <LabelInfo
                  identifier="influencerBudget"
                  title="Influencer Budget"
                  info={Instructions.InfluencerBudget}
                />

                <div className="ageInputContainer">
                  {/* min age */}
                  <div className="dateContainer">
                    <InputNumber
                      disabled={values?.isBarter}
                      style={{ width: "100%" }}
                      status={
                        touched.influencerBudget?.min &&
                        errors.influencerBudget?.min
                          ? "error"
                          : null
                      }
                      name="values?.influencerBudget.min"
                      value={values?.influencerBudget.min}
                      size="large"
                      placeholder="₹X,XXX"
                      className="ageInput"
                      onChange={(amount) =>
                        handleNumberChange(amount, "influencerBudget.min")
                      }
                      onBlur={handleBlur}
                    />
                    {touched.influencerBudget?.min &&
                    errors.influencerBudget?.min ? (
                      <div className="alertMessage">
                        {errors.influencerBudget?.min}
                      </div>
                    ) : null}
                  </div>
                  <Typography className="font ageSeparatorText">To</Typography>
                  {/* max age */}
                  <div className="dateContainer">
                    <InputNumber
                      style={{ width: "100%" }}
                      status={
                        touched.influencerBudget?.max &&
                        errors.influencerBudget?.max
                          ? "error"
                          : null
                      }
                      onChange={(amount) =>
                        handleNumberChange(amount, "influencerBudget.max")
                      }
                      name="values?.influencerBudget.max"
                      value={values?.influencerBudget.max}
                      min={values?.influencerBudget.min}
                      disabled={
                        values?.influencerBudget.min === "" ||
                        values?.influencerBudget.min === null ||
                        values?.isBarter
                      }
                      size="large"
                      placeholder="₹X,XXX"
                      className="ageInput"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="flexBetween">
              {/* switch for deciding wether the campaign is Barter type or not */}
              <div
                className="dateContainer"
                style={{ width: "39%", marginRight: 20 }}
              >
                <LabelInfo
                  identifier="influencerCount"
                  title="No. Of influencer"
                  info={Instructions.InfluencerRequired}
                />

                <InputNumber
                  style={{ width: "100%" }}
                  status={
                    touched.influencerRequired && errors.influencerRequired
                      ? "error"
                      : null
                  }
                  name="values?.influencerRequired"
                  value={values?.influencerRequired}
                  min={0}
                  size="large"
                  placeholder="Influencer needed"
                  className="ageInput"
                  onChange={(amount) =>
                    handleNumberChange(amount, "influencerRequired")
                  }
                />
              </div>
              {/* minimum and maximum number of influencer needed for the campaign */}
              <div style={{ flex: 1 }}>
                <LabelInfo
                  title="Followers range"
                  info={Instructions.FollowersRange}
                  identifier="followersRange"
                />
                <div className="ageInputContainer">
                  {/* minimum number of influencer need for the campaign*/}
                  <div className="dateContainer">
                    <InputNumber
                      style={{ width: "100%" }}
                      status={
                        touched.followersRange?.min &&
                        errors.followersRange?.min
                          ? "error"
                          : null
                      }
                      name="values?.followersRange.min"
                      value={values?.followersRange.min}
                      min={0}
                      size="large"
                      placeholder="min"
                      className="ageInput"
                      onChange={(amount) =>
                        handleNumberChange(amount, "followersRange.min")
                      }
                    />
                  </div>
                  {/* separator */}
                  <Typography className="font ageSeparatorText">To</Typography>
                  {/* maximum number of influencer need for the campaign*/}
                  <div className="dateContainer">
                    <InputNumber
                      status={
                        touched.followersRange?.max &&
                        errors.followersRange?.max
                          ? "error"
                          : null
                      }
                      style={{ width: "100%" }}
                      onChange={(amount) =>
                        handleNumberChange(amount, "followersRange.max")
                      }
                      name="values?.followersRange.max"
                      value={values?.followersRange.max}
                      min={values?.followersRange.min + 500}
                      disabled={
                        values?.followersRange.min === "" ||
                        values?.followersRange.min === null
                      }
                      size="large"
                      placeholder="max"
                      className="ageInput"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>
      {/* next and previous buttons */}
      <Row gutter={[16, 24]} className="mt3">
        <Col xs={12} md={12} lg={12}>
          <Button
            fullWidth
            variant="outlined"
            className="prevButton"
            onClick={handlePrevButton}
          >
            Previous
          </Button>
        </Col>
        <Col xs={12} md={12} lg={12}>
          <Button
            fullWidth
            variant="contained"
            className="nextButton"
            onClick={handleNextButton}
          >
            Next
          </Button>
        </Col>
      </Row>
    </StepTwoWrapper>
  );
};

export default StepTwoForm;
