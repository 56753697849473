import React, { useEffect } from "react";
import { Row } from "antd";
import { useSelector } from "react-redux";
import StepFourForm from "../../../components/forms/CreateCampaign/StepFour/StepFourForm";
import StepOneForm from "../../../components/forms/CreateCampaign/StepOne";
import StepThreeForm from "../../../components/forms/CreateCampaign/StepThree";
import StepTwoForm from "../../../components/forms/CreateCampaign/StepTwo";
import StepZero from "../../../components/forms/CreateCampaign/StepZero";
import CardHeading from "../../../components/headings/CardHeading";
import AntSteps from "../../../components/stepper/AntSteps";
import { ImageList } from "../../../config/ImageList";
import DesktopLayout from "../../../theme/DesktopLayout";
import useViewport from "../../../viewport/useViewport";
import { CreateCampaignWrapper } from "./CreateCampaignStyle";
import { LocalStorageIdentifier } from "../../../config/LocalStorageInfo";

const CreateCampaign = () => {
  const { currentStep } = useSelector((state) => state.stepper);
  const { isMidrange, isTablet } = useViewport();
  useEffect(() => {
    if (
      localStorage.getItem(LocalStorageIdentifier.createCampaignData) === null
    ) {
      localStorage.setItem(LocalStorageIdentifier.createCampaignData, "");
      localStorage.setItem(LocalStorageIdentifier.stepZeroData, "");
      localStorage.setItem(LocalStorageIdentifier.stepOneData, "");
      localStorage.setItem(LocalStorageIdentifier.stepTwoData, "");
      localStorage.setItem(LocalStorageIdentifier.stepThreeData, "");
      localStorage.setItem(LocalStorageIdentifier.stepFourData, "");
      localStorage.setItem(
        LocalStorageIdentifier.isCreateCampaignComplete,
        JSON.stringify(false)
      );
    }
  }, []);

  return (
    <DesktopLayout>
      <CreateCampaignWrapper isMidrange={isMidrange} isTablet={isTablet}>
        <AntSteps />
        <div className="mt2 formContainer">
          {currentStep === 0 ? (
            <div style={{ width: "100%" }}>
              <div className="headingContainer">
                <img
                  className="loudspeakerIcon"
                  src={ImageList.LoudspeakerImageIcon}
                  alt="speaker"
                />{" "}
                <CardHeading title="Campaign Info" weight="700" />
              </div>
              <Row gutter={[16, 24]} className="mt4">
                <StepZero />
              </Row>
            </div>
          ) : currentStep === 1 ? (
            <>
              {" "}
              <div className="headingContainer">
                <img
                  className="loudspeakerIcon"
                  src={ImageList.LoudspeakerImageIcon}
                  alt="speaker"
                />{" "}
                <CardHeading title="Campaign Info" weight="700" />
              </div>
              <StepOneForm />{" "}
            </>
          ) : currentStep === 2 ? (
            <>
              {" "}
              <div className="headingContainer">
                <CardHeading title="Influencer Info" weight="700" />
              </div>
              <StepTwoForm />{" "}
            </>
          ) : currentStep === 3 ? (
            <>
              {" "}
              <div className="headingContainer">
                <CardHeading title="Campaign Media" weight="700" />
              </div>
              <StepThreeForm />{" "}
            </>
          ) : (
            <>
              {" "}
              <div className="headingContainer">
                <CardHeading title="Publish Your Campaign" weight="700" />
              </div>
              <StepFourForm />{" "}
            </>
          )}
        </div>
      </CreateCampaignWrapper>
    </DesktopLayout>
  );
};

export default CreateCampaign;
