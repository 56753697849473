import { Button, Typography } from "@mui/material";
import { Progress } from "antd";
import React from "react";
import { Colors } from "../../../config/Colors";
import {
  calculatePercentage,
  numberWithCommas,
} from "../../../config/Function";
import { Icons } from "../../../config/IconsList";
import { SingleCampaignMoneyWrapper } from "./Style";

const SingleCampaignMoneyInfo = ({ isGradient }) => {
  return (
    <SingleCampaignMoneyWrapper isGradient={isGradient}>
      <div className="mainBox">
        <Typography variant="h4" className="price">
          {" "}
          <Icons.RupeeIcon style={{ fontSize: 18, marginRight: 3 }} />
          {numberWithCommas(3300)}
        </Typography>
        <Typography className="title">Money paid by the brand</Typography>
        <Typography className="percentPaid">
          <span style={{ color: Colors.textGreen }}>
            {calculatePercentage(3300, 56000)}%
          </span>{" "}
          (<Icons.RupeeIcon style={{ fontSize: 12, marginRight: 3 }} />
          {numberWithCommas(56000)})
        </Typography>
        <Button className="addCreditButton mt1" variant="contained" fullWidth>
          Add credit
        </Button>
        {/* progress div */}
        <div className="flexBetween mt3">
          <div style={{ width: "48%" }}>
            <Typography variant="body1" className="progressHeading">
              Completion: {calculatePercentage(60, 100)}%
            </Typography>
            <Progress
              showInfo={false}
              strokeColor={Colors.textGreen}
              status="active"
              percent={60}
              trailColor={Colors.white}
            />
          </div>
          <div style={{ width: "48%" }}>
            <Typography variant="body1" className="progressHeading">
              Unpaid money: {calculatePercentage(40, 100)}%
            </Typography>
            <Progress
              showInfo={false}
              strokeColor={Colors.lightOrange}
              status="active"
              percent={40}
              trailColor={Colors.white}
            />
          </div>
        </div>
      </div>
    </SingleCampaignMoneyWrapper>
  );
};

export default SingleCampaignMoneyInfo;
