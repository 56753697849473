import { Typography } from "@mui/material";
import { Button, Empty, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { LocalStorageIdentifier } from "../../../../config/LocalStorageInfo";
import { getAllBrandsAPI } from "../../../../functions/brand";
import { changeStepCount } from "../../../../redux/stepSlice";
import NormalLabel from "../../../inputLabel/NormalLabel";
import { StepZeroWrapper } from "./Style";

const StepZero = () => {
  const { currentStep } = useSelector((state) => state.stepper);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // state
  const [data, setData] = useState([]);
  const [dataToRender, setDataToRender] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedBrand, setSelectedBrand] = useState({});

  // ==============================================
  // =================useEffect====================
  // ==============================================
  useEffect(() => {
    if (user?.token !== "") {
      listOfBrands();
    }
  }, [user]);
  useEffect(() => {
    // variable brand will have the value of the selected brand
    let brand =
      localStorage.getItem(LocalStorageIdentifier.stepZeroData) !== "" &&
      JSON.parse(localStorage.getItem(LocalStorageIdentifier.stepZeroData));
    setSelectedBrand(brand);
  }, [user]);

  // ==============================================
  // =================functions====================
  // ==============================================

  // this stands a condition that if localstorage value is not empty string then only parse it
  let myValue =
    localStorage.getItem(LocalStorageIdentifier.createCampaignData) !== "";

  // call all the brands
  const listOfBrands = () => {
    const values = {
      page: 1,
      limit: 100,
      filterType: "role",
      filterValue: "brand",
    };

    getAllBrandsAPI(values, user?.token)
      .then((res) => {
        console.log("ALL BRANDS LIST==>", res.data);
        setData(res.data.users);
      })
      .catch((err) => console.log(err));
  };

  const handleSearch = (newValue) => {
    setSearchText(newValue);
    const filteredUsers = data.filter((user) =>
      user.name.toLowerCase().includes(newValue.toLowerCase())
    );
    setDataToRender(filteredUsers);
  };
  // every time select is changed value of the brand Id is stored
  const handleChange = (newValue) => {
    console.log("STEP ZERO VALUE", newValue);
    let label = newValue.label;
    let value = newValue.value;
    let brandUserId = { brandUserId: newValue.value };

    const brandFound = data.filter((item) => item._id === value)[0];
    // set step zero data to localstorage
    localStorage.setItem(
      LocalStorageIdentifier.stepZeroData,
      JSON.stringify({ label, value, url: brandFound.profilePicture.url })
    );
    setSelectedBrand({
      label,
      value,
      url: brandFound.profilePicture.url,
    });
    // save the step zero data to localstorage createCampaignData
    let savedData =
      myValue &&
      JSON.parse(
        localStorage.getItem(LocalStorageIdentifier.createCampaignData)
      );
    localStorage.setItem(
      LocalStorageIdentifier.createCampaignData,
      JSON.stringify({ ...savedData, ...brandUserId })
    );
  };
  // function runs when Next button is clicked
  const handleNextButton = () => {
    dispatch(
      changeStepCount({
        data: "abc",
        currentStep: currentStep < 4 ? currentStep + 1 : 4,
      })
    );

    console.log("NEXT");
  };

  return (
    <StepZeroWrapper className="flexCenter" style={{ width: "100%" }}>
      {/* {JSON.stringify(selectedBrand, null, 4)} */}
      <Typography className="font caption mb2">
        Our platform makes it easy for your brand to find and connect with
        influencers who align with your brand values and goals.
      </Typography>
      <NormalLabel title="Brand Identifier" identifier="brandIdentifier" />
      <Select
        showSearch
        value={selectedBrand}
        placeholder="Select Brand"
        style={{
          width: "80%",
        }}
        labelInValue
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={<Empty />}
        options={(searchText === "" ? data.slice(0, 100) : dataToRender)?.map(
          (d) => ({
            value: d._id,
            label: d.name,
          })
        )}
      />

      <Button
        style={{
          width: "80%",
        }}
        fullWidth
        variant="contained"
        className="nextButton mt2"
        onClick={handleNextButton}
        disabled={selectedBrand?.value === ""}
      >
        Next
      </Button>
    </StepZeroWrapper>
  );
};

export default StepZero;
