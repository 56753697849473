import React from "react";
import styled from "styled-components";
import { Colors } from "../../../config/Colors";

const MessageText = ({ currentUserId, message }) => {
  let isMe = message.senderId === currentUserId;
  return (
    <TextWrapper isMe={isMe} className="flexBetween">
      {message.text}
    </TextWrapper>
  );
};

export default MessageText;

// styling
const TextWrapper = styled.div`
  width: fit-content;
  max-width: 80%;
  min-height: 2rem;
  padding: 0.7rem;
  max-height: fit-content;
  position: relative;
  border-radius: ${(prop) =>
    prop.isMe ? "10px 0px 10px 10px" : "0px 10px 10px 10px"};
  margin-top: 1rem;
  color: ${(prop) => prop.isMe && Colors.white};
  background: ${(prop) => (prop.isMe ? Colors.lightPurple : Colors.messageBg)};
  align-self: ${(prop) => prop.isMe && "flex-end"};
  line-height: 24px;
`;
