import React, { useEffect, useState } from "react";
import DesktopLayout from "../../../theme/DesktopLayout";
import { AllStoryWrapper } from "./AllStoryStyle";
import { Avatar, message, Table, Tag } from "antd";
import { Typography } from "@mui/material";
import { formatDate, formatDateWithTime } from "../../../config/Function";
import { useSelector } from "react-redux";
import { deleteStoryAPI, getStoriesAPI } from "../../../functions/story";

const { Column } = Table;

const AllStory = () => {
  const user = useSelector((state) => state.user);
  const [stories, setStories] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalStoriesCount, setTotalStoriesCount] = useState(0);

  useEffect(() => {
    if (user !== "") {
      getAllStories();
    }
  }, [user, page, limit]);

  const getAllStories = () => {
    getStoriesAPI(page, limit, user?.token)
      .then((res) => {
        console.log(res.data);
        setStories(res.data?.stories);
        setTotalStoriesCount(res.data?.totalStoryCount);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination?.current);
    setLimit(pagination?.pageSize);
  };

  const deleteStory = (storyId) => {
    console.log("STORY ID", storyId);
    if (window.confirm("Are you sure you want to delete?")) {
      deleteStoryAPI(storyId, user?.token)
        .then((res) => {
          console.log(res.data);
          message.success("Story deleted successfully");
          getAllStories();
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
        });
    }
  };

  return (
    <DesktopLayout>
      <AllStoryWrapper>
        <Table
          dataSource={stories[0]?.posts}
          onChange={handleTableChange}
          pagination={{
            current: page,
            pageSize: limit,
            pageSizeOptions: [20, 50, 75, 100],
            total: totalStoriesCount,
          }}
          scroll={{
            y: 540,
            x: 1200,
          }}
        >
          <Column
            width={150}
            title="Story Type"
            dataIndex="storyType"
            key="storyType"
            fixed="left"
          />

          <Column
            title="Uploaded On"
            dataIndex="createdAt"
            key="createdAt"
            width={200}
            render={(item) => (
              <Typography variant="body2">
                {formatDate(item)}, {formatDateWithTime(item)}{" "}
              </Typography>
            )}
          />
          <Column
            title="Story"
            dataIndex="storyMediaUrl"
            key="storyMediaUrl"
            width={200}
            render={(item) => {
              return (
                <>
                  {item === "" ? (
                    <Avatar size="large" shape="square">
                      T
                    </Avatar>
                  ) : (
                    <a
                      href={item === "" ? "##" : item}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Avatar
                        src={item?.replace(".mp4", ".jpg")}
                        size="large"
                        shape="square"
                      />
                    </a>
                  )}
                </>
              );
            }}
          />

          <Column
            title="Story Text"
            dataIndex="textMessage"
            key="textMessage"
            width={350}
          />
          <Column
            title="Action"
            key="action"
            render={(_, record) => (
              <Tag
                color="red"
                onClick={() => deleteStory(record._id)}
                style={{ cursor: "pointer" }}
              >
                Delete
              </Tag>
            )}
          />
        </Table>
      </AllStoryWrapper>
    </DesktopLayout>
  );
};

export default AllStory;
