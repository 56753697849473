import { Divider, message, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Colors } from "../../../config/Colors";
import { TeamMembers } from "../../../config/DummyData";
import { getTeamMembersAPI } from "../../../functions/user";
import CardHeading from "../../headings/CardHeading";
import NormalText from "../../headings/NormalText";
import { TeamMemberWrapper } from "./TeamMembersStyled";

const TeamMembersCard = () => {
  const user = useSelector((state) => state.user);
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    if (user?.token !== "") {
      getmyTeamMembers();
    }
  }, [user]);

  const getmyTeamMembers = () => {
    getTeamMembersAPI(user?.token)
      .then((res) => {
        setTeamMembers(res.data.users);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };

  return (
    <TeamMemberWrapper>
      <CardHeading title="Team Members" />
      <div className="main_box mt3">
        {teamMembers.map((member, i) => {
          return (
            <div key={i}>
              {/* divider */}
              {i !== 0 && <Divider style={{ margin: ".8em 0" }} />}
              <div className="flexBetween">
                {/* user image */}
                <img
                  src={member?.profilePicture?.url}
                  alt={member?.firstName}
                  className="memberImage"
                />
                <div className="contentContainer flexBetween">
                  {/* user name and status */}
                  <div className="textContainer">
                    <NormalText
                      title={member?.firstName + " " + member?.lastName}
                    />
                    {member?.onlineStatus === "online" ? (
                      <Tag
                        color="success"
                        className="mtp4 tag"
                        style={{
                          background: Colors.faintedGreen,
                          color: Colors.textGreen,
                        }}
                      >
                        Online
                      </Tag>
                    ) : (
                      <Tag
                        color="error"
                        className="mtp4 tag"
                        style={{
                          background: Colors.faintedPink,
                          color: Colors.alertRed,
                        }}
                      >
                        Offline
                      </Tag>
                    )}
                  </div>
                  {/* user role */}
                  <div className="roleText flexCenter"> {member?.role} </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </TeamMemberWrapper>
  );
};

export default TeamMembersCard;
