import axios from "axios";

export const getInfluencerListAPI = async (page, limit, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API_ADMIN}/influencers?page=${page}&limit=${limit}`,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const createTopCreatorAPI = async (values, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API_ADMIN}/top-creator`,
    values,
    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};

export const getAllTopCreatorsAPI = async (value, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/user/top-creators-v2?page=${value?.page}&limit=${value?.limit}`,

    {
      headers: {
        authorization: authtoken,
      },
    }
  );
};
