import React, { useEffect, useState } from "react";
import { Empty, message, Pagination, Progress, Spin, Tooltip } from "antd";
import { HomePageBrandData } from "../../../../config/DummyData";
import { CampaignTableWrapper } from "./CampaignWiseInfoStyle";
import { Colors } from "../../../../config/Colors";
import { Icons } from "../../../../config/IconsList";
import { Typography } from "@mui/material";
import useViewport from "../../../../viewport/useViewport";
import ViewAllButton from "../../../Button/ViewAllButton";
import { useSelector } from "react-redux";
import { getRevenueByCampaignAPI } from "../../../../functions/campaign";
import { PaginationWrapper } from "../AllCampaignScreen/Style";

const CampaignWiseInfo = ({ all }) => {
  const { isTablet } = useViewport();
  const user = useSelector((state) => state.user);
  const [campaigns, setCampaigns] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (user?.token !== "") {
      getRevenueByCampaign();
    }
  }, [user, page, limit]);

  const getRevenueByCampaign = () => {
    setLoading(true);
    getRevenueByCampaignAPI(page, limit, user?.token)
      .then((res) => {
        console.log("REVENUE BY CAMPAIGN==>", res.data.data);
        setCampaigns(res.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error(err.response.data.message);
      });
  };
  // page  size change: number of item to be displayed on a single page
  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
    setLimit(pageSize);
  };
  // change page number
  const handleChangePageNumber = (page, pageSize) => {
    setPage(page);
  };

  return (
    <>
      <CampaignTableWrapper
        all={all}
        className={all ? "none" : "mb2"}
        isTablet={isTablet}
      >
        <div
          className="flexCenter"
          style={{ flexDirection: "column", width: "100%" }}
        >
          <table className="tableParent">
            {/* table header row */}
            <tr>
              <th className="tableHeader">Campaign </th>
              <th className="tableHeader">Budget </th>
              <th className="tableHeader">Status </th>
              <th className="tableHeader">
                <Tooltip title="Shortlisted influencers against the total required influencers">
                  Completion
                </Tooltip>
              </th>
            </tr>
            {/* table info row */}
            {campaigns.slice(0, 6).map((campaign, i) => {
              return (
                <tr key={i}>
                  <td>
                    <div className="statusContainer">
                      <img
                        src={campaign?.campaignBanner}
                        alt="logo"
                        className="brand_logo"
                      />
                      <Typography variant="body2" className="campaignName">
                        {campaign?.campaignName.substring(0, 25) + "..."}
                        <br />
                        <span className="brandName">
                          {campaign?.brandName}{" "}
                        </span>
                      </Typography>
                    </div>
                  </td>
                  <td>
                    <div className="statusContainer">
                      <Icons.RupeeIcon style={{ fontSize: "small" }} />
                      {campaign?.budget}
                    </div>
                  </td>
                  <td>
                    <div className="statusContainer" style={{ fontSize: 13 }}>
                      <div
                        className="statusText"
                        style={{
                          background:
                            campaign?.status === "working"
                              ? Colors.lightPurple
                              : campaign?.status === "canceled"
                              ? Colors.alertRed
                              : Colors.textGreen,
                          marginRight: 8,
                        }}
                      />
                      {campaign?.status}
                    </div>
                  </td>
                  <td>
                    <Progress
                      strokeLinecap="round"
                      percent={campaign?.completionPercentage}
                      status="active"
                      strokeColor={{
                        "0%":
                          campaign?.status === "canceled"
                            ? Colors.alertRed
                            : campaign?.status === "completed"
                            ? Colors.textGreen
                            : "#108ee9",
                        "100%":
                          campaign?.status === "canceled"
                            ? Colors.alertRed
                            : campaign?.status === "completed"
                            ? Colors.textGreen
                            : campaign?.completionPercentage < 50
                            ? Colors.alertRed
                            : "#87d068",
                      }}
                    />
                  </td>
                </tr>
              );
            })}
            {/* view all button */}
            {!all && (
              <tr>
                <td
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    position: "relative",
                    bottom: 0,
                  }}
                >
                  <ViewAllButton url="/campaign/all/info" />
                </td>
              </tr>
            )}
            {/* table ends here */}
          </table>
          {loading && <Spin />}
          {campaigns?.length < 1 && (
            <div
              className="flexCenter mt2"
              style={{
                width: "100%",
                alignSelf: "center",
              }}
            >
              <Empty />
            </div>
          )}
        </div>
      </CampaignTableWrapper>

      {/* pagination */}
      {all && (
        <PaginationWrapper>
          <Pagination
            pageSizeOptions={[1, 4, 10, 50, 100]}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            defaultCurrent={1}
            total={500}
            onChange={handleChangePageNumber}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

export default CampaignWiseInfo;
