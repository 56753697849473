import React, { useState } from "react";
import styled from "styled-components";
import { BlogData } from "../../config/DummyData";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import { Colors } from "../../config/Colors";
import { Typography } from "@mui/material";
import { BorderRadius, PaddingValues, Shadow } from "../../config/Values";

const BlogSynopCarousel = () => {
  // this state wether the sidenav is expanded or not

  const [showIndex, setShowIndex] = useState(0);

  const changeIndex = (type) => {
    if (type === "next") {
      if (
        BlogData?.length - 1 === showIndex ||
        BlogData?.length - 1 < showIndex
      ) {
        setShowIndex(0);
      } else {
        setShowIndex((prev) => prev + 1);
      }
    }
    // if (type === "prev")
    else {
      if (showIndex === 0) {
        setShowIndex(BlogData.length - 1);
      } else {
        setShowIndex((prev) => prev - 1);
      }
    }
  };

  const arrowStyle = {
    color: Colors.textGrey,
    fontSize: "1.5em",
  };

  function SampleNextArrow() {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: 10,
          cursor: "pointer",
          right: "1.42em",
          top: "5%",
          background: "rgba(0,0,0,0.1)",
          borderRadius: "4em",
          width: "1.6em",
          height: "1.6em",
        }}
        className="flexCenter"
        onClick={() => changeIndex("next")}
      >
        <KeyboardArrowRightOutlinedIcon
          style={arrowStyle}
          className="carouselController"
        />
      </div>
    );
  }

  function SamplePrevArrow() {
    return (
      <div
        style={{
          position: "absolute",
          zIndex: 100,
          cursor: "pointer",
          right: "3.42em",
          borderRadius: "4em",
          top: "5%",
          background: "rgba(0,0,0,0.1)",
          width: "1.6em",
          height: "1.6em",
        }}
        className="flexCenter"
        onClick={() => changeIndex("prev")}
      >
        <KeyboardArrowLeftOutlinedIcon
          style={arrowStyle}
          className="carouselController"
        />
      </div>
    );
  }
  return (
    <SliderWrapper>
      {BlogData.map((item, i) => {
        return (
          <div key={i} style={{ height: "100%" }}>
            {i === showIndex && (
              <div className="main_container">
                {SampleNextArrow()}
                {SamplePrevArrow()}
                <img
                  src={item.imageUrl}
                  alt="BlogImage"
                  className="blog_image"
                />
                <Typography variant="h6" className="blogTitle">
                  {item.title}
                </Typography>
              </div>
            )}
          </div>
        );
      })}
    </SliderWrapper>
  );
};

export default BlogSynopCarousel;

const SliderWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: ${BorderRadius.mediumRadius};
  overflow: hidden;
  box-shadow: ${Shadow.dark};
  .main_container {
    width: 100%;
    height: 100%;
    border-radius: ${BorderRadius.mediumRadius};
    background: ${Colors.white};
    /* overflow: hidden; */
  }
  .blog_image {
    width: 100%;
    height: 100%;
    /* aspect-ratio: ${(prop) =>
      prop.isSideNavExpanded ? "2/1.7355" : "2/1.693"}; */
    object-fit: cover;
    border-radius: ${BorderRadius.mediumRadius};
    object-position: center;
    transition: all 1s ease-in-out;
  }
  .blogTitle {
    position: absolute;
    bottom: 10%;
    color: ${Colors.white};
    padding: 0 ${PaddingValues.smallPadding};
    transition: all 1s ease-in-out;
  }
`;
