import { Badge, Typography } from "@mui/material";
import {
  Badge as MyRibbon,
  Col,
  Row,
  Tooltip,
  Avatar,
  Image,
  Switch,
  message,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CampaignDescription from "../../../components/description/CampaignDescription";
import LargeHeading from "../../../components/headings/LargeHeading";
import DosDontsList from "../../../components/list/DosAndDonts/DosDontsList";
import AppliedInfluencers from "../../../components/table/campaign/SingleCampaignScreen/AppliedInfluencer";
import LiveInfluencerCampaign from "../../../components/table/campaign/SingleCampaignScreen/LiveInfluencerCampaign/LiveInfluencerCampaign";
import ShortlistedInfluencers from "../../../components/table/campaign/SingleCampaignScreen/ShortlistedInfluencer";
import TagWithIcon from "../../../components/tag/TagWithIcon";
import { Colors } from "../../../config/Colors";
import { formatDate, nFormatter } from "../../../config/Function";
import { Icons } from "../../../config/IconsList";
import { ImageList } from "../../../config/ImageList";
import {
  addEditorChoiceAPI,
  getSingleCampaignDetailAPI,
  getSubmittedLinksAPI,
} from "../../../functions/campaign";
import DesktopLayout from "../../../theme/DesktopLayout";
import useViewport from "../../../viewport/useViewport";
import { SingleCampaignWrapper } from "./Style";
import AddCreditToCampaign from "../../../components/forms/AddCredit/AddCreditToCampaign";

const SingleCampaign = () => {
  const { campaignId } = useParams();
  const user = useSelector((state) => state.user);
  const { isMobile, isTablet } = useViewport();
  const [campaignDetail, setCampaignDetail] = useState({});
  const [displayedImage, setDisplayedImage] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [isBarter, setIsBarter] = useState(false);
  const [ribbonTitle, setRibbonTitle] = useState("Banner");
  const [submittedLinks, setSubmittedLinks] = useState([]);
  // this state keeps an eye on all the actions that could be taken in this page
  // and as soon as any action is taken which may effect other array, it calls those functions again to render the new data
  const [appliedToShortlist, setAppliedToShortlist] = useState(1);
  const [shortlistToLive, setShortlistToLive] = useState(1);
  // useEffect
  useEffect(() => {
    if (user) {
      getCampaignData();
    }
    if (campaignId) {
      getSubmittedLinks();
    }
  }, [campaignId, user]);

  // retrieve the data of campaign from the _id received
  const getCampaignData = () => {
    getSingleCampaignDetailAPI(campaignId, user?.token)
      .then((res) => {
        console.log("SINGLE CAMPAIGN DATA,", res.data);
        setCampaignDetail(res.data?.campaign);
        setPaymentType(res.data.campaign?.campaignPaymentType);
        setIsBarter(res.data.campaign?.isBarter);
      })
      .catch((err) => console.log(err));
  };

  // replace bigger image by clicked image
  const handleImageChange = (imageUrl, imageType) => {
    setDisplayedImage(imageUrl);
    setRibbonTitle(imageType);
  };

  // helping jsx for small images
  const SmallImages = (imageUrl, tooltipTitle, imageType, isVideo) => {
    return (
      <Tooltip title={tooltipTitle} style={{ overflow: "hidden" }}>
        {isVideo ? (
          <a
            href={imageUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="smallImage flexCenter videoDiv"
          >
            Video{" "}
            <Icons.OpenNewTabIcon style={{ fontSize: 14, marginLeft: 5 }} />
          </a>
        ) : (
          <img
            src={imageUrl}
            alt={imageType}
            className="smallImage"
            onClick={() => handleImageChange(imageUrl, imageType)}
          />
        )}
      </Tooltip>
    );
  };
  // run this function every time editor choice is toggled
  const handleEditorChoice = (checked) => {
    const myValues = {
      campaignId: campaignId,
      isAdding: checked,
    };
    // run the api stating it is the editor's choice
    addEditorChoiceAPI(myValues, user?.token)
      .then((res) => {
        console.log("EDITORS CHOICE==>", res.data);
        if (res.data.success) {
          if (checked) {
            message.success("This campaign has been added as Editor's Choice");
          } else {
            message.info("This campaign has been removed from Editor's Choice");
          }
        } else {
          message.warning(
            "This campiagn could not be added as Editor's Choice"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };
  // get the list of influencer with submittedLinks
  const getSubmittedLinks = () => {
    getSubmittedLinksAPI(campaignId, user?.token)
      .then((res) => {
        console.log("SUBMITTED LINKS IN SUBMITTED LINKS=====>", res.data);
        setSubmittedLinks(res?.data?.links);
      })
      .catch((err) => {
        console.log("ERROR IN SUBMITTED LINKS", err);
      });
  };

  return (
    <DesktopLayout>
      <SingleCampaignWrapper isMobile={isMobile}>
        <Row gutter={[0, 24]}>
          <Col xs={24} md={10} lg={8}>
            {/*=================================Images===================================== */}
            <MyRibbon.Ribbon
              placement="start"
              color={Colors.lightPurple}
              text={ribbonTitle}
            >
              <Image
                width="100%"
                src={
                  displayedImage === ""
                    ? campaignDetail?.campaignBanner
                    : displayedImage
                }
                alt={campaignDetail?.campaignTitle}
                className="campaignBanner"
              />
            </MyRibbon.Ribbon>
            <div className="otherImagesContainer mtp5">
              {/* product reference */}
              {campaignDetail?.productReference?.map((item, i) => (
                <div key={i}>
                  {SmallImages(
                    item.imageUrl,
                    `Product Reference :- ${item.productName} at ₹ ${item.price}`,
                    "Product Reference",
                    false
                  )}
                </div>
              ))}
              {/* reference images for post */}
              {campaignDetail?.referenceImage?.map((item, i) => (
                <div key={i}>
                  {SmallImages(
                    item,
                    `Reference Image`,
                    "Reference Image",
                    false
                  )}
                </div>
              ))}
              {/* video reference */}
              {campaignDetail?.referenceVideo?.map((item, i) => (
                <div key={i}>
                  {SmallImages(
                    item,
                    "Reference Video",
                    "Reference Video",
                    true
                  )}
                </div>
              ))}
              {/* banner */}
              {displayedImage !== "" &&
                SmallImages(
                  campaignDetail.campaignBanner,
                  `Banner`,
                  "Campaign Banner"
                )}
            </div>
            {/*=================================Tags===================================== */}
            <div className="tagWrapper mt1">
              {/* barter  */}
              <TagWithIcon
                title={`Barter: ${isBarter ? "Yes" : "No"} `}
                iconUrl={ImageList.HandshakeImageIcon}
                disabled={isBarter ? false : true}
              />
              {/* budget tag */}
              <TagWithIcon
                title={`Budget: ${nFormatter(
                  campaignDetail?.pricePerInfluencer?.min
                )} to ${nFormatter(campaignDetail?.pricePerInfluencer?.max)} `}
                iconUrl={ImageList.MoneyBagImageIcon}
                disabled={isBarter}
              />{" "}
              {/* categories tag */}
              <TagWithIcon
                title={campaignDetail?.campaignCategories?.map((item, i) => (
                  <span key={i} style={{ marginRight: "0.2rem" }}>
                    {item.categoryName}{" "}
                    {/* The role and aria-hidden attributes are added to the span element to ensure that it is properly accessible to screen readers. */}
                    <span
                      role="presentation"
                      aria-hidden="true"
                      style={{
                        display:
                          i ===
                            campaignDetail?.campaignCategories?.length - 1 &&
                          "none",
                      }}
                    >
                      {/* The dot element is created using an HTML span element with a middle dot character as its content. */}
                      &middot;
                    </span>
                  </span>
                ))}
                iconUrl={ImageList.TagsImageIcon}
              />
            </div>

            {/*=================================Deliverables Tags===================================== */}
            <Typography className="deliverableHeading">Deliverables</Typography>
            <div className="deliverableTagsContainer">
              {campaignDetail?.deliverableType?.map((item, i) => {
                let imageUrl =
                  item === "instaReel"
                    ? ImageList.InstaReelImageIcon
                    : item === "instagramPost"
                    ? ImageList.InstagramImageIcon
                    : item === "instagramStory"
                    ? ImageList.InstagramImageIcon
                    : item === "facebookPost"
                    ? ImageList.FacebookImageIcon
                    : item === "youtubeShorts"
                    ? ImageList.YoutubeShortsImageIcon
                    : ImageList.YouTubeImageIcon;
                return <TagWithIcon key={i} title={item} iconUrl={imageUrl} />;
              })}
            </div>
            <Switch
              className="mt2"
              checkedChildren="Editor's Choice"
              unCheckedChildren="Normal"
              onChange={handleEditorChoice}
            />
          </Col>

          {/*==================================== **************** ======================================*/}
          {/*======================================= 2nd Column ======================================*/}
          {/*==================================== **************** ======================================*/}

          <Col xs={24} md={14} lg={16}>
            <div className="contentContainer">
              <div style={{ paddingRight: !isTablet && "5.5rem" }}>
                {" "}
                <LargeHeading title={campaignDetail?.campaignName} />
              </div>

              {/* brand logo, name, status and campaign time period */}
              <div className="brandContainer flexBetween mt1">
                {/* this section has brand logo and brand name */}
                <div className="brandInfo">
                  <Badge
                    overlap="circular"
                    badgeContent={
                      <img
                        src={ImageList.GoldenTickImageIcon}
                        alt="tick"
                        className="verificationTick"
                      />
                    }
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <Avatar
                      shape="circle"
                      src={campaignDetail?.brand?.profilePicture?.url}
                    />
                  </Badge>
                  <Typography className="brandName font">
                    {campaignDetail?.brand?.name}
                  </Typography>
                </div>
                {/* campaign status and campaign time period */}
                <div className="statusPeriodContainer flexBetween">
                  <TagWithIcon
                    title="Running"
                    background={Colors.tagGreenBg}
                    color={Colors.darkGreen}
                    iconUrl={ImageList.RunningCampaignImageIcon}
                  />
                  <TagWithIcon
                    title={
                      formatDate(campaignDetail?.campaignPeriod?.startDate) +
                      " - " +
                      formatDate(campaignDetail?.campaignPeriod?.endDate)
                    }
                    iconUrl={ImageList.CalenderImageIcon}
                    iconDimension=".9rem"
                  />
                </div>
              </div>
              <Button
                href={`/admin/campaign/insta/data/${campaignId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="mt2"
              >
                {" "}
                Campaign Data{" "}
              </Button>
              {/* ====================================DESCRIPTION =========================== */}
              <CampaignDescription
                campaignDetail={campaignDetail}
                apiCall={true}
              />
              {/* ========================INFO TAGS=========================================== */}

              <div
                className="mt1 mb2"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {" "}
                <TagWithIcon
                  title={`Age: ${campaignDetail?.age?.min}-${campaignDetail?.age?.max} years`}
                  iconUrl={ImageList.HumanHeadImageIcon}
                />
                <TagWithIcon
                  title={`Sex: ${
                    campaignDetail?.gender === "Male & Female"
                      ? "Male & Female"
                      : campaignDetail?.gender
                  }`}
                  iconUrl={ImageList.UserImageIcon}
                />
                {campaignDetail?.campaignPlatform?.platformName ===
                "youtube" ? (
                  <TagWithIcon
                    title={`Subscribers: >${nFormatter(
                      campaignDetail?.subscribers?.min
                    )}`}
                    iconUrl={ImageList.YouTubeImageIcon}
                  />
                ) : (
                  <TagWithIcon
                    title={`Followers: ${nFormatter(
                      campaignDetail?.followersRange?.min
                    )}-${nFormatter(campaignDetail?.followersRange?.max)}`}
                    iconUrl={campaignDetail?.campaignPlatform?.platformLogo}
                  />
                )}
                <TagWithIcon
                  title={`Followers: ${nFormatter(
                    campaignDetail?.followersRange?.min
                  )}-${nFormatter(campaignDetail?.followersRange?.max)}`}
                  iconUrl={campaignDetail?.campaignPlatform?.platformLogo}
                />
              </div>
              {/* Do's and Don'ts */}
              <DosDontsList campaignDetail={campaignDetail} />
            </div>
          </Col>
        </Row>
      </SingleCampaignWrapper>
      {/*==================================== **************** ======================================*/}
      {/*======================================= Transfer Credit ROW ================================*/}
      {/*==================================== **************** ======================================*/}
      {/* this row contains the input box stating how much does this campaign is worth in terms of credit */}
      <Row gutter={[16, 24]}>
        <Col span={24} style={{ display: "flex" }}>
          <AddCreditToCampaign
            campaignId={campaignId}
            brandId={campaignDetail?.brand?._id}
            credits={campaignDetail?.totalCreditsTransferred}
            commissionPercent={campaignDetail?.commissionPercentage}
          />
        </Col>
      </Row>
      {/*==================================== **************** ======================================*/}
      {/*======================================= 2nd ROW ======================================*/}
      {/*==================================== **************** ======================================*/}
      {/* 2nd row with All applied influencer table and shortlisted influencer table */}

      <Row gutter={[16, 24]} className="mt2">
        <Col xs={24} md={11} lg={10} style={{ display: "flex" }}>
          <AppliedInfluencers
            campaignId={campaignId}
            all={false}
            brandId={campaignDetail?.brand?._id}
            appliedToShortlist={appliedToShortlist}
            setAppliedToShortlist={setAppliedToShortlist}
          />
        </Col>
        <Col xs={24} md={13} lg={14} style={{ display: "flex" }}>
          <ShortlistedInfluencers
            campaignId={campaignId}
            all={false}
            brandId={campaignDetail?.brand?._id}
            appliedToShortlist={appliedToShortlist}
            setAppliedToShortlist={setAppliedToShortlist}
          />
        </Col>
      </Row>

      {/*==================================== **************** ======================================*/}
      {/*======================================= 3rd ROW ======================================*/}
      {/*==================================== **************** ======================================*/}
      {/* 3rd row with All influencer whose content was approved posted it online and shared the link. 
      This is the table from which payment will be dispatched to the influencer
      */}
      {/* {JSON.stringify(submittedLinks, 2, 4)}
      {JSON.stringify(campaignId, 2, 4)}
      {JSON.stringify(campaignDetail?.brand?._id, 2, 4)} */}

      <Row gutter={[16, 24]}>
        <Col xs={24} md={24} lg={24}>
          <LiveInfluencerCampaign
            campaignDetail={campaignDetail}
            campaignId={campaignId}
            all={false}
            brandId={campaignDetail?.brand?._id}
          />
        </Col>
      </Row>
    </DesktopLayout>
  );
};

export default SingleCampaign;
