import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { snackbarAlert } from "../../redux/snackbarSlice";
import { Alert, Slide } from "@mui/material";

export default function CustomSnackbar() {
  const dispatch = useDispatch();
  const snackbarInfo = useSelector((state) => state.snackbarInfo);
  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      snackbarAlert({
        message: "",
        open: false,
        messageType: "",
      })
    );
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={snackbarInfo.open}
        autoHideDuration={5000}
        TransitionComponent={SlideTransition}
        key={snackbarInfo.message}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        message={snackbarInfo.message}
        action={action}>
        <Alert
          onClose={handleClose}
          severity={snackbarInfo.messageType}
          sx={{ width: "100%" }}>
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
