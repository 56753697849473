import { Chip, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../config/Colors";
import { FontType } from "../../config/Values";

const TagWithIcon = ({
  iconUrl,
  title,
  disabled,
  background,
  color,
  iconDimension,
  ifMarginNotSpecified,
}) => {
  return (
    <TagWrapper
      background={background}
      ifMarginNotSpecified={ifMarginNotSpecified}
      fontColor={color}
      iconDimension={iconDimension}
      size="small"
      disabled={disabled}
      icon={<img src={iconUrl} alt="icon" className="iconImage" />}
      label={<Typography className="tagTitle"> {title} </Typography>}
    />
  );
};

export default TagWithIcon;

const TagWrapper = styled(Chip)`
  opacity: ${(prop) => prop.disabled && "0.5"};
  border: ${(prop) =>
    prop.background ? "none" : `1px solid ${Colors.greyC9}`} !important;
  background-color: ${(prop) =>
    prop.background ? prop.background : Colors.greyF7} !important;
  color: ${(prop) =>
    prop.fontColor ? prop.fontColor : Colors.tagText}!important;
  margin-right: ${(prop) => (!prop.ifMarginNotSpecified ? "1rem" : 0)};
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: auto 2.5rem !important;
  .iconImage {
    width: ${(prop) => (prop.iconDimension ? prop.iconDimension : "1.3rem")};
    height: ${(prop) => (prop.iconDimension ? prop.iconDimension : "1.3rem")};
    margin-left: ${(prop) => prop.iconDimension && "0.5rem"} !important;
  }
  .tagTitle {
    opacity: ${(prop) => prop.disabled && "0.7"};
    font-family: ${FontType.openSans}, sans-serif !important;
    font-size: 0.7rem;
    font-weight: 600;
    text-transform: capitalize !important;
  }
`;
