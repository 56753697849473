import React, { useEffect, useState } from "react";
import { AddCreditWrapper } from "./Style";
import useViewport from "../../../viewport/useViewport";
import { Button, Col, Input, InputNumber, Row, message } from "antd";
import NormalLabel from "../../inputLabel/NormalLabel";
import { useFormik } from "formik";
import { TransferCreditSchema } from "../../../config/Schemas";
import { transferCampaignCreditAPI } from "../../../functions/campaign";
import { useSelector } from "react-redux";

const initialValues = {
  campaignId: "",
  credits: 0,
  ingenkartCommission: 10,
  brandId: "",
};

const AddCreditToCampaign = ({
  campaignId,
  brandId,
  credits,
  commissionPercent,
}) => {
  const { isTablet } = useViewport();
  const user = useSelector((state) => state.user);
  //   state
  const [loading, setLoading] = useState(false);
  //   useEffect
  //   every time page loads: set the value of campaignId in formik
  useEffect(() => {
    handleChange({ target: { name: "campaignId", value: campaignId } });
    handleChange({ target: { name: "brandId", value: brandId } });
    handleChange({ target: { name: "credits", value: credits } });
    handleChange({
      target: { name: "ingenkartCommission", value: commissionPercent },
    });
  }, [campaignId, brandId]);

  // ===========================************==================================
  // ===========================useFormik=====================================
  // ===========================*************=================================
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: TransferCreditSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      console.log(values);
      transferCampaignCreditAPI(values, user?.token)
        .then((res) => {
          if (res.data.success) {
            setLoading(false);
            message.success("Credit has been transferred successfully");
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          message.error(err?.response?.data?.message);
        });
    },
  });

  return (
    <AddCreditWrapper isTablet={isTablet}>
      <Row gutter={24}>
        <Col xs={24} md={8}>
          <NormalLabel title="Credit" identifier="credit" />
          <InputNumber
            placeholder="Credit"
            id="credit"
            name="credits"
            status={errors?.credits && touched?.credits}
            value={values?.credits}
            style={{ width: "100%" }}
            onChange={(e) =>
              handleChange({ target: { name: "credits", value: e } })
            }
          />
        </Col>
        <Col xs={24} md={8}>
          <NormalLabel title="Ingenkart Commission" identifier="commission" />
          <InputNumber
            placeholder="Ingenkart Commission"
            id="commission"
            value={values?.ingenkartCommission}
            name="ingenkartCommission"
            status={errors?.ingenkartCommission && touched?.ingenkartCommission}
            onChange={(e) =>
              handleChange({
                target: { name: "ingenkartCommission", value: e },
              })
            }
            style={{ width: "100%" }}
          />
        </Col>
        <Col xs={24} md={8}>
          <NormalLabel title="" />
          <Button
            onClick={handleSubmit}
            disabled={values?.credits < 1 || loading}
          >
            {loading ? "Loading..." : "Transfer Credit"}
          </Button>
        </Col>
      </Row>
    </AddCreditWrapper>
  );
};

export default AddCreditToCampaign;
