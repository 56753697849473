import { Divider } from "@mui/material";
import { Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { UserChatList } from "../../../config/DummyData";
import MessageAreaNav from "../Nav/MessageAreaNav";
import Search from "../Search";
import SingleUserInfoCard from "../SingleUserInfoCard";

import { MessageAreaWrapper } from "./Style";

const MessageArea = () => {
  const user = useSelector((state) => state.user);
  let userToPreview = 1;
  const [searchQuery, setSearchQuery] = useState("");

  // filter list of user based on the id of the currentUser and return the list to which this user had any conversation
  const ChatList = UserChatList.filter((item) => item.id === userToPreview)[0]
    .connection;
  // filter user based on the search query typed
  const filteredItems = ChatList.filter((item) => {
    return item.otherUserName.toLowerCase().includes(searchQuery.toLowerCase());
  });
  // if search query has any input then run filtered array else other array
  let ArrayToRender = searchQuery === "" ? ChatList : filteredItems;
  // handle search
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  return (
    <MessageAreaWrapper>
      {/* nav bar stating who is the user currently logged in (mostly admin or brand) */}
      <MessageAreaNav user={user} />
      <Search searchQuery={searchQuery} handleSearch={handleSearch} />
      <Divider className="dividerLine" />
      {/* main container where different users will be there */}
      <div className="chatUserBox">
        {ArrayToRender?.length > 0 ? (
          ArrayToRender.map((item, i) => {
            return <SingleUserInfoCard item={item} key={i} i={i} />;
          })
        ) : (
          <div style={{ textAlign: "center" }} className="mt2">
            No user found
          </div>
        )}
      </div>
    </MessageAreaWrapper>
  );
};

export default MessageArea;
