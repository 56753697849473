import { Divider, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getProgressTrackAPI } from "../../../functions/campaign";
import CardHeading from "../../headings/CardHeading";
import { ProgressTrackWrapper } from "./ProgressTrackStyle";
import SingleCampaignTrack from "./SingleCampaignTrack";

const ProgressTrack = () => {
  const user = useSelector((state) => state?.user);
  const [progressData, setProgressData] = useState(null);

  useEffect(() => {
    if (user?.token !== "") {
      getProgressData();
    }
  }, [user]);

  const getProgressData = () => {
    getProgressTrackAPI(user?.token)
      .then((res) => {
        setProgressData(res.data?.data);
        console.log("PROGRESS TRACK=>", res.data);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };

  return (
    <ProgressTrackWrapper>
      <CardHeading title="Progress Track" />
      <div className="mt3">
        {progressData?.map((campaign, i) => {
          return (
            <div key={i}>
              {i !== 0 && <Divider style={{ margin: ".8em 0" }} />}
              <SingleCampaignTrack i={i} campaign={campaign} />
            </div>
          );
        })}
      </div>
    </ProgressTrackWrapper>
  );
};

export default ProgressTrack;
