import { createSlice } from "@reduxjs/toolkit";

export const chatUserSlice = createSlice({
  name: "chatUser",
  initialState: {
    uid: "",
    firstName: "",
    lastName: "",
    imageUrl: "",
  },
  reducers: {
    chatUser: (state, action) => {
      state.uid = action.payload.uid;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.imageUrl = action.payload.imageUrl;
    },
  },
});

export const { chatUser } = chatUserSlice.actions;
export default chatUserSlice.reducer;
