import { Button, Typography } from "@mui/material";
import { Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../../../config/IconsList";
import { LocalStorageIdentifier } from "../../../../config/LocalStorageInfo";
import { editCampaignAPI } from "../../../../functions/campaign";
import { changeStepCount } from "../../../../redux/stepSlice";
import { FormFourWrapper } from "./Style";

const StepFourForm = ({ campaignId }) => {
  const { currentStep } = useSelector((state) => state.stepper);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSaved, setDataSaved] = useState({});
  let myValue =
    localStorage.getItem(LocalStorageIdentifier.editCampaignData) !== "";
  useEffect(() => {
    // retrieve the data until the current step
    let savedData =
      myValue &&
      JSON.parse(localStorage.getItem(LocalStorageIdentifier.editCampaignData));
    setDataSaved(savedData);
  }, [currentStep]);

  // function runs when Next button is clicked
  const handlePublishButton = () => {
    // this will fire the API call to edit the campaign
    editCampaignAPI(campaignId, dataSaved, user?.token)
      .then((res) => {
        message.success("Campaign Edited Successfully.😊");
        localStorage.setItem(LocalStorageIdentifier.editCampaignData, "");
        localStorage.setItem(LocalStorageIdentifier.editStepZeroData, "");
        localStorage.setItem(LocalStorageIdentifier.editStepOneData, "");
        localStorage.setItem(LocalStorageIdentifier.editStepTwoData, "");
        localStorage.setItem(LocalStorageIdentifier.editStepThreeData, "");
        localStorage.setItem(LocalStorageIdentifier.editStepFourData, "");
        localStorage.setItem(
          LocalStorageIdentifier.isEditCampaignComplete,
          JSON.stringify(true)
        );
        dispatch(
          changeStepCount({
            data: "abc",
            currentStep: 0,
          })
        );
        // store the current step in localstorage which will help to know that till what step is completed
        localStorage.setItem(
          LocalStorageIdentifier.editStepCompleted,
          JSON.stringify(1)
        );
        navigate("/all/campaigns", { replace: true });
      })
      .catch((err) => {
        console.log(err);
        message.error("Some error occured.😖");
      });

    console.log("NEXT");
  };
  // function runs when Next button is clicked
  const handlePrevButton = () => {
    dispatch(
      changeStepCount({
        data: "abc",
        currentStep: currentStep - 1,
      })
    );
  };

  return (
    <FormFourWrapper>
      {/* {JSON.stringify(
        JSON.parse(
          localStorage.getItem(
            LocalStorageIdentifier.createCampaignData,
            null,
            4
          )
        )
      )} */}
      <Typography variant="body2" className="mt1">
        You have filled all the essential information required for the campaign
        to launch.
        <br />
        <br />
        Once you click on the Publish button, the campaign will go under review
        and we will connect you to the earliest and make this campaign go live
        on App.
      </Typography>
      <Row gutter={[16, 24]} className="mt4">
        <Col xs={8} md={8} lg={8}>
          <Button
            fullWidth
            variant="outlined"
            className="prevButton"
            onClick={handlePrevButton}
          >
            Previous
          </Button>
        </Col>
        <Col xs={8} md={8} lg={8}>
          <Button
            fullWidth
            variant="contained"
            className="nextButton"
            onClick={handlePublishButton}
          >
            Publish
          </Button>
        </Col>
        <Col xs={8} md={8} lg={8}>
          <a
            href="/preview/campaign"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              endIcon={<Icons.OpenInNewTab />}
              fullWidth
              variant="outlined"
              className="prevButton"
            >
              Preview
            </Button>
          </a>
        </Col>
      </Row>
    </FormFourWrapper>
  );
};

export default StepFourForm;
