import styled from "styled-components";
import { Colors } from "../../../config/Colors";
import { BorderRadius } from "../../../config/Values";

export const AddCreditWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background-color: ${Colors.white};
  border-radius: ${BorderRadius.mediumRadius};
  overflow-x: ${(prop) => prop.isTablet && "scroll"};
  margin-top: 2rem;
  padding: 1.5rem;
  ::-webkit-scrollbar {
    display: none;
  }
`;
