import { Button, Typography } from "@mui/material";
import { Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../../../config/IconsList";
import { LocalStorageIdentifier } from "../../../../config/LocalStorageInfo";
import { createCampaignAPI } from "../../../../functions/campaign";
import { changeStepCount } from "../../../../redux/stepSlice";
import { FormFourWrapper } from "./Style";

const StepFourForm = () => {
  const { currentStep } = useSelector((state) => state.stepper);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSaved, setDataSaved] = useState({});
  let myValue =
    localStorage.getItem(LocalStorageIdentifier.createCampaignData) !== "";
  useEffect(() => {
    // retrieve the data until the current step
    let savedData =
      myValue &&
      JSON.parse(
        localStorage.getItem(LocalStorageIdentifier.createCampaignData)
      );
    setDataSaved(savedData);
  }, [currentStep]);

  // function runs when Next button is clicked
  const handlePublishButton = () => {
    dispatch(
      changeStepCount({
        data: "abc",
        currentStep: currentStep < 4 ? currentStep + 1 : 4,
      })
    );
    // this will fire the API call to create the campaign
    createCampaignAPI(dataSaved, user.token)
      .then((res) => {
        message.success("Campaign Created Successfully.😊");
        localStorage.setItem(LocalStorageIdentifier.createCampaignData, "");
        localStorage.setItem(LocalStorageIdentifier.stepZeroData, "");
        localStorage.setItem(LocalStorageIdentifier.stepOneData, "");
        localStorage.setItem(LocalStorageIdentifier.stepTwoData, "");
        localStorage.setItem(LocalStorageIdentifier.stepThreeData, "");
        localStorage.setItem(LocalStorageIdentifier.stepFourData, "");
        localStorage.setItem(
          LocalStorageIdentifier.isCreateCampaignComplete,
          JSON.stringify(true)
        );
        // store the current step in localstorage which will help to know that till what step is completed
        localStorage.setItem(
          LocalStorageIdentifier.stepCompleted,
          JSON.stringify(1)
        );
        navigate("/all/campaigns", { replace: true });
      })
      .catch((err) => {
        console.log(err);
        message.error("Some error occured.😖");
      });

    console.log("NEXT");
  };
  // function runs when Next button is clicked
  const handlePrevButton = () => {
    dispatch(
      changeStepCount({
        data: "abc",
        currentStep: currentStep - 1,
      })
    );
  };

  return (
    <FormFourWrapper>
      {/* {JSON.stringify(
        JSON.parse(
          localStorage.getItem(LocalStorageIdentifier.createCampaignData)
        )
      )} */}
      <Typography variant="body2" className="mt1">
        You have filled all the essential information required for the campaign
        to launch.
        <br />
        <br />
        Once you click on the Publish button, the campaign will go under review
        and we will connect you to the earliest and make this campaign go live
        on App.
      </Typography>
      <Row gutter={[16, 24]} className="mt4">
        <Col xs={8} md={8} lg={8}>
          <Button
            fullWidth
            variant="outlined"
            className="prevButton"
            onClick={handlePrevButton}
          >
            Previous
          </Button>
        </Col>
        <Col xs={8} md={8} lg={8}>
          <Button
            fullWidth
            variant="contained"
            className="nextButton"
            onClick={handlePublishButton}
          >
            Publish
          </Button>
        </Col>
        <Col xs={8} md={8} lg={8}>
          <a
            href="/preview/campaign"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              endIcon={<Icons.OpenInNewTab />}
              fullWidth
              variant="outlined"
              className="prevButton"
            >
              Preview
            </Button>
          </a>
        </Col>
      </Row>
    </FormFourWrapper>
  );
};

export default StepFourForm;
