import { Avatar, Button, Icon, Typography } from "@mui/material";
import {
  Dropdown,
  Empty,
  Input,
  message,
  Pagination,
  Spin,
  Switch,
  Tag,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Colors } from "../../../config/Colors";
import { validate } from "react-email-validator";
import { Icons } from "../../../config/IconsList";
import {
  SnackbarSeverity,
  UserRole,
  VerificationStatus,
} from "../../../config/Values";
import { getAllUsersAPI, verifyUserAPI } from "../../../functions/user";
import { snackbarAlert } from "../../../redux/snackbarSlice";
import useViewport from "../../../viewport/useViewport";
import { AllUserTableWrapper, PaginationWrapper } from "./Style";
import { capitalizeFirstLetter } from "../../../config/Function";
import {
  createTopCreatorAPI,
  getAllTopCreatorsAPI,
} from "../../../functions/influencer";
import SmallHeading from "../../headings/SmallHeading";

const AllUserTable = ({ topcreatorPage }) => {
  const { isTablet } = useViewport();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  //   different states
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [role, setRole] = useState("all");
  const [status, setStatus] = useState("all");
  const [expanded, setExpanded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [userList, setUserList] = useState();
  const [totalTopCtreators, setTotalTopCtreators] = useState(0);
  const [topCreatorsList, setTopCreatorsList] = useState([]);
  const [filter, setFilter] = useState({
    filterType: "status",
    filterValue: "all",
  });
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [joinedReordering, setJoinedReordering] = useState(false);
  // ===============useEffect============================
  useEffect(() => {
    setLoadingUsers(true);
    if (user?.token) {
      getAllUserList();
    }
    // console.log("USE EFFECT IS BEING TRIGGERED");
  }, [currentPage, pageSize, role, status, user]);

  useEffect(() => {
    chenageOrderOfUserByJoiningDate();
  }, [joinedReordering]);
  useEffect(() => {
    if (user) {
      getTopCreatorsList();
    }
  }, [user]);
  //   ==============================FUNCTIONS==================================
  // page  size change: number of item to be displayed on a single page
  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
    setPageSize(pageSize);
  };
  // change page number
  const handleChangePageNumber = (page, pageSize) => {
    setCurrentPage(page);
  };
  const getTopCreatorsList = () => {
    const myValues = {
      page: 1,
      limit: 150,
    };
    getAllTopCreatorsAPI(myValues, user?.token)
      .then((res) => {
        setTopCreatorsList(res.data.influencers);
        setTotalTopCtreators(res.data.totalCount);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };
  //   change role
  const handleRoleChange = ({ key }) => {
    let myRoleObj = UserRole?.find((item) => item.key === key);
    console.log("HELLO", myRoleObj.role);
    setRole(myRoleObj.role);
    // this will help to filter the user on the basis of  role
    setFilter({
      filterType: "role",
      filterValue: myRoleObj.role,
    });
  };
  //   change status
  const handleStatusChange = ({ key }) => {
    let myRoleObj = VerificationStatus?.find((item) => item.key === key);
    console.log("HELLO", myRoleObj.status);
    setStatus(myRoleObj.status);
    // this will help to filter the user on the basis of status
    setFilter({
      filterType: "status",
      filterValue: myRoleObj.status,
    });
  };
  //   toggle search bar width
  const handleSearchInputExpansion = (e) => {
    e.preventDefault();
    setExpanded(true);
    const filterType = validate(searchText) ? "email" : "name";
    if (searchText !== "") {
      // this will help to filter the user on the basis of filterType
      const values = {
        page: currentPage,
        limit: pageSize,
        filterType,
        filterValue: searchText,
      };
      getAllUsersAPI(values, user?.token)
        .then((res) => {
          console.log("ALL USERS==>", res.data);
          setUserList(res.data.users.reverse());
          setLoadingUsers(false);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
        });
      // run api for search
      console.log(searchText);
    }
  };
  // get the list of all users in database based on pagination
  const getAllUserList = () => {
    const values = {
      page: currentPage,
      limit: pageSize,
      ...filter,
    };
    getAllUsersAPI(values, user?.token)
      .then((res) => {
        console.log("ALL USERS==>", res.data);
        setUserList(res.data.users.reverse());
        setLoadingUsers(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };

  const chenageOrderOfUserByJoiningDate = () => {
    setUserList(userList?.reverse());
  };

  // accept or reect the user verification based on button click

  const verifyApliedUser = (userId, status) => {
    const statusText = status === "accepted" ? "approve" : "reject";
    if (
      window.confirm(`Do you want to ${statusText} the verification request?`)
    ) {
      verifyUserAPI({ userId, status }, user?.token)
        .then((res) => {
          if (res.data.success) {
            message.success("User has been verified successfully");
            getAllUserList();
          }
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
        });
    }
  };
  // switch for making Top Creator toggle
  const createTopCreatorToggle = (checked, id) => {
    // setsettingValues({ ...settingValues, [name]: checked });
    console.log("TOP CREATOR REQUEST==>", checked, id);
    const myValues = {
      influencerId: id,
      isAdding: checked,
    };
    createTopCreatorAPI(myValues, user?.token)
      .then((res) => {
        message.success("Action taken successfully.");
        getTopCreatorsList();
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };

  return (
    <>
      <SmallHeading
        title={`Total Top Creators: ${totalTopCtreators}`}
        color={Colors.white}
        weight="700"
        size="1.5rem"
      />
      <AllUserTableWrapper isTablet={isTablet} expanded={expanded}>
        <div
          className="flexCenter"
          style={{ flexDirection: "column", width: "100%" }}
        >
          <table className="tableParent">
            {/* table header row */}
            <thead className="headerContainer">
              <tr>
                <th className="tableHeader flexBetween">
                  <p>Name</p>
                  <div className="flexBetween searchContainer">
                    <Input
                      placeholder="Search user by name"
                      allowClear={expanded && true}
                      className="searchInputBox"
                      onChange={(e) => setSearchText(e.target.value)}
                      onPressEnter={handleSearchInputExpansion}
                      prefix={
                        <Icons.SearchIcon
                          onClick={handleSearchInputExpansion}
                          // disabled={searchText === "" && !expanded}
                        />
                      }
                      onBlur={() => setExpanded(false)}
                    />
                  </div>
                </th>
                <th className="tableHeader centerAlignText">
                  <Dropdown
                    menu={{ items: UserRole, onClick: handleRoleChange }}
                    placement="bottom"
                  >
                    <span style={{ cursor: "pointer" }}>Role</span>
                  </Dropdown>
                </th>
                <th className="tableHeader centerAlignText">
                  <Dropdown
                    menu={{
                      items: VerificationStatus,
                      onClick: handleStatusChange,
                    }}
                    placement="bottom"
                  >
                    <span style={{ cursor: "pointer" }}>Status</span>
                  </Dropdown>
                </th>
                <th className="tableHeader centerAlignText">Phone</th>
                <th className="tableHeader centerAlignText">
                  Joined
                  <span style={{ marginLeft: 10 }}>
                    <Icons.SwapOutlined
                      onClick={() => setJoinedReordering((val) => !val)}
                      style={{ transform: "rotate(90deg)" }}
                    />
                  </span>{" "}
                </th>
                <th className="tableHeader centerAlignText">Top Creator</th>
              </tr>
            </thead>

            {/* table body */}
            <tbody>
              {userList?.slice(0, pageSize).map((item, i) => {
                const isTopCreator = topCreatorsList?.find(
                  (v) => v.influencerId._id === item._id
                );

                return (
                  <tr key={i}>
                    {/* name and avatar */}
                    <td>
                      <a
                        href={`/user/${item?._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="tableData"
                      >
                        <Avatar
                          variant="rounded"
                          sx={{ width: 37, height: 37 }}
                          style={{ borderRadius: 10 }}
                          src={item?.profilePicture?.url}
                        />
                        <div className="influencerText">
                          <Typography className="name">
                            {" "}
                            {item?.firstName} {item?.lastName}
                          </Typography>
                          <Typography className="email">
                            {item?.email}
                          </Typography>
                        </div>
                      </a>
                    </td>
                    {/* role */}
                    <td className="centerAlignText">{item?.role}</td>
                    {/* status */}
                    <td className="centerAlignText">
                      <Tag
                        style={{
                          color: item?.isVerified
                            ? Colors.textGreen
                            : Colors.alertRed,
                          textTransform: "uppercase",
                          fontSize: 8,
                        }}
                        color={
                          item?.isVerified
                            ? Colors.faintedGreen
                            : Colors.faintedPink
                        }
                      >
                        {item?.isVerified ? "Verified" : "Unverified"}
                      </Tag>
                    </td>
                    {/* mobile number */}
                    <td className="centerAlignText">{item?.phone}</td>
                    {/* joining date */}
                    <td className="centerAlignText">
                      {moment(item?.createdAt).format("MMM Do YY")}
                    </td>
                    {/* actions */}
                    <td className="centerAlignText">
                      {item?.status !== "inreview" ? (
                        <>
                          {item?.status === "rejected" && !topcreatorPage && (
                            <Tag
                              style={{
                                color: Colors.lightPurple,
                                cursor: "pointer",
                              }}
                              color={Colors.inputBg}
                            >
                              {capitalizeFirstLetter(item?.status)}
                            </Tag>
                          )}
                          {!topcreatorPage && (
                            <Button
                              style={{
                                color: Colors.alertRed,
                                cursor: "pointer",
                                fontSize: "0.5rem",
                              }}
                              variant="text"
                              href={`/edit/${item?._id}/user`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Edit
                            </Button>
                          )}
                          {topcreatorPage && item?.role === "influencer" ? (
                            <Switch
                              checked={isTopCreator}
                              onChange={(e) =>
                                createTopCreatorToggle(e, item._id)
                              }
                              className="switch"
                            />
                          ) : (
                            "- -"
                          )}
                        </>
                      ) : (
                        <>
                          {topcreatorPage ? (
                            <>
                              {item?.role === "influencer" ? (
                                <Switch
                                  checked={isTopCreator}
                                  onChange={(e) =>
                                    createTopCreatorToggle(e, item._id)
                                  }
                                  className="switch"
                                />
                              ) : (
                                "- -"
                              )}
                            </>
                          ) : (
                            <>
                              {" "}
                              <Tag
                                style={{
                                  color: Colors.lightPurple,
                                  cursor: "pointer",
                                }}
                                color={Colors.inputBg}
                                onClick={() =>
                                  verifyApliedUser(item?._id, "accepted")
                                }
                              >
                                Verify
                              </Tag>{" "}
                              <Tag
                                style={{
                                  color: Colors.alertRed,
                                  cursor: "pointer",
                                }}
                                color={Colors.faintedPink}
                                onClick={() =>
                                  verifyApliedUser(item?._id, "rejected")
                                }
                              >
                                Reject
                              </Tag>
                              <>
                                <Dropdown
                                  menu={{
                                    items: VerificationStatus,
                                    onClick: handleStatusChange,
                                  }}
                                  placement="bottom"
                                >
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      color: Colors.lightPurple,
                                    }}
                                  >
                                    Others
                                  </span>
                                </Dropdown>
                              </>
                              <Button
                                style={{
                                  color: Colors.alertRed,
                                  cursor: "pointer",
                                  fontSize: "0.5rem",
                                }}
                                variant="text"
                                href={`/edit/${item?._id}/user`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Edit
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {userList?.length < 1 && (
            <div
              className="flexCenter mt2"
              style={{
                width: "100%",
                alignSelf: "center",
              }}
            >
              <Empty />
            </div>
          )}
          {loadingUsers && (
            <div
              className="flexCenter mt2"
              style={{
                width: "100%",
                alignSelf: "center",
              }}
            >
              <Spin />
              <br />
              <Typography>Loading...</Typography>
            </div>
          )}
        </div>
      </AllUserTableWrapper>
      {/* pagination */}
      <PaginationWrapper>
        <Pagination
          pageSizeOptions={[4, 10, 50, 100]}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={1}
          total={500}
          onChange={handleChangePageNumber}
        />
      </PaginationWrapper>
    </>
  );
};

export default AllUserTable;
