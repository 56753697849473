import { Avatar, Button, Chip, Typography } from "@mui/material";
import { Drawer, Empty, message } from "antd";
import React, { useState } from "react";
import { Colors } from "../../../../config/Colors";
import { Icons } from "../../../../config/IconsList";
import { ImageList } from "../../../../config/ImageList";
import useViewport from "../../../../viewport/useViewport";
import CardHeading from "../../../headings/CardHeading";
import AssignmentSubmittedModal from "../../../modal/AssignmentSubmittedModal";
import ReshootModal from "../../../modal/ReshootModal";
import TagIconOutlined from "../../../tag/TagIconOutlined";
import TagWithBg from "../../../tag/TagWithBg";
import { ShortlistedWrapper } from "./Style";
import { approveOrRejectMediaAPI } from "../../../../functions/campaign";
import { useSelector } from "react-redux";

const ShortlistedInfluencerDrawer = ({
  shortlistedInfluencers,
  all,
  openDrawer,
  onClose,
  setPage,
  toatlInfluencerCount,
  setLimit,
  page,
  campaignId,
  brandId,
}) => {
  const user = useSelector((state) => state.user);
  const { isTablet, isMidrange } = useViewport();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReshootModalOpen, setIsReshootModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showReshootModal = () => {
    setIsReshootModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsReshootModalOpen(false);
  };
  // this function check if the demo media url links are approved or not
  function checkDemoApproval(arr) {
    console.log("DEMO APPROVAL LINKS==>", arr);
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].isDemoApproved === true) {
        console.log("At least one object is approved", i);
        return true; // At least one object is approved
      }
    }
    return false; // No object is approved
  }
  // reject influencer who has applied for this campaign
  const rejectInfluencer = (influencerId) => {
    if (window.confirm("Are you sure you want to reject this influencer?")) {
      message.warning("Influencer has been asked to reshoot the campaign");
      // this api call will make the rejection call by setting the isApproved to false
      approveTheDeliverables(influencerId, false);
    }
  };
  // connectionId ==>  brandId + influencerId + campaignId
  // room ===> brandId + campaignId;
  const approveTheDeliverables = (influencerId, isApproved) => {
    const connectionId = brandId + influencerId + campaignId;
    const room = brandId + influencerId;
    const myValues = {
      isApproved,
      connectionId,
      room: room,
      brandId,
      senderId: brandId,
    };
    if (window.confirm("Are you sure you want to approve this deliverables?")) {
      approveOrRejectMediaAPI(myValues, user?.token)
        .then((res) => {
          message.success("Media has been approved.");
        })
        .catch((err) => console.log(err.response.data.message));
    }
  };
  return (
    <>
      <Drawer
        width={640}
        placement="right"
        closable={false}
        onClose={onClose}
        open={openDrawer}
      >
        <AssignmentSubmittedModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
        <ReshootModal isOpen={isReshootModalOpen} handleCancel={handleCancel} />
        <ShortlistedWrapper isTablet={isTablet}>
          <CardHeading title="Shortlisted Influencers" />
          <>
            <table className="tableParent mt1" style={{ width: "100%" }}>
              {/* header */}
              <tr>
                <th className="tableHeader" style={{ textAlign: "left" }}>
                  Name
                </th>
                <th className="tableHeader">Status</th>
                <th className="tableHeader">Submission</th>
                <th className="tableHeader" style={{ paddingLeft: 60 }}>
                  Action
                </th>
              </tr>

              {shortlistedInfluencers?.map((item, i) => {
                let myStatus =
                  item?.mediaUrlLinks?.length === 0 ? "pending" : "submitted";
                let isEq = item?.mediaUrlLinks?.length === 0 ? true : false;

                const isApproved =
                  item?.mediaUrlLinks?.length > 0
                    ? checkDemoApproval(item?.mediaUrlLinks)
                    : false;

                return (
                  <tr key={i} className="tableRow">
                    <td>
                      <a
                        href={`/user/${item?.user?._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="tableData"
                      >
                        <Avatar
                          variant="rounded"
                          sx={{ width: 37, height: 37 }}
                          style={{ borderRadius: 10 }}
                          src={item?.profilePicture?.url}
                        />
                        <div className="influencerText">
                          <Typography className="name">
                            {" "}
                            {item?.firstName + " " + item?.lastName}{" "}
                          </Typography>
                          <Typography className="email">
                            {item?.email}
                          </Typography>
                        </div>
                      </a>
                    </td>
                    <td className="appliedCol">
                      <TagWithBg status={myStatus} title={myStatus} />
                    </td>
                    <td className="appliedCol">
                      {isEq ? (
                        <div className="dash"> - - </div>
                      ) : (
                        <TagIconOutlined
                          title="View"
                          iconUrl={ImageList.EyeImageIcon}
                          onClick={showModal}
                        />
                      )}
                    </td>
                    <td className="centerAlignText">
                      {isApproved ? (
                        <div className="dash actionDash"> - - </div>
                      ) : !isApproved && item?.mediaUrlLinks?.length > 0 ? (
                        <>
                          <Chip
                            clickable
                            label="Approve"
                            size="small"
                            onClick={() =>
                              approveTheDeliverables(item?._id, true)
                            }
                            style={{
                              color: Colors.darkGreen,
                              cursor: "pointer",
                              textAlign: "center",
                              backgroundColor: Colors.tagGreenBg,
                              borderRadius: "8px",
                              fontWeight: "300",
                              marginRight: !isMidrange && 8,
                              marginTop: isMidrange && 8,
                            }}
                            icon={
                              <img
                                src={ImageList.AcceptFilledImageIcon}
                                alt="accept influencer"
                                className="tagIcon"
                              />
                            }
                          />

                          <Chip
                            clickable
                            onClick={() => rejectInfluencer(item?._id)}
                            label="Reshoot"
                            size="small"
                            style={{
                              color: Colors.textBlack,
                              cursor: "pointer",
                              borderRadius: "8px",
                              background: Colors.tagGreyBg,
                              fontWeight: "300",
                              marginTop: isMidrange && 8,
                            }}
                            icon={
                              <img
                                src={ImageList.ReshootImageIcon}
                                alt="accept influencer"
                                className="tagIcon"
                              />
                            }
                          />
                        </>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </table>
            {/* && appliedInfluencers?.totalCount !== page * limit */}
            {all && toatlInfluencerCount > shortlistedInfluencers?.length && (
              <tr>
                <td
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Button
                    onClick={() => setLimit((prev) => prev + 20)}
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                      color: Colors.textGrey,
                      textTransform: "capitalize",
                    }}
                    endIcon={<Icons.AddIcon style={{ fontSize: 14 }} />}
                  >
                    Load More
                  </Button>
                </td>
              </tr>
            )}
            {shortlistedInfluencers?.length < 1 && (
              <div
                className="flexCenter mt2"
                style={{
                  width: "100%",
                  alignSelf: "center",
                }}
              >
                <Empty />
              </div>
            )}
          </>
        </ShortlistedWrapper>
      </Drawer>
    </>
  );
};

export default ShortlistedInfluencerDrawer;
