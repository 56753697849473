import React, { useEffect, useState } from "react";
import DesktopLayout from "../../../theme/DesktopLayout";
import {
  Button,
  Col,
  Drawer,
  Input,
  Row,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { Colors } from "../../../config/Colors";
import { useSelector } from "react-redux";
import {
  unverifiedInstaUserAPI,
  unverifiedInstaUserBySearchAPI,
  verifyInstaProfileAPI,
} from "../../../functions/user";
import { UnverifiedInstaWrapper } from "./Styled";
import CardHeading from "../../../components/headings/CardHeading";
import { Icons } from "../../../config/IconsList";
import NormalLabel from "../../../components/inputLabel/NormalLabel";
const { Text, Link } = Typography;
const UnverifiedInstaUser = () => {
  // hooks
  const user = useSelector((state) => state.user);
  // local state
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState(0);
  const [verifiableUser, setVerifiableUser] = useState("");
  // lifecycle method
  useEffect(() => {
    if (user) {
      getAllUnverifiedInstaUser();
    }
  }, [user]);

  // =================================================================
  // functions
  // ===================================================================
  const getAllUnverifiedInstaUser = () => {
    setLoading(true);
    setUsername("");
    unverifiedInstaUserAPI(page, limit, user?.token)
      .then((res) => {
        setUserList(res.data.instaData);
      })

      .catch((err) => {
        console.log("UNVERIFIED INSTA USER LIST ERROR==>", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserBySearch = () => {
    setLoading(true);

    unverifiedInstaUserBySearchAPI(username, user?.token)
      .then((res) => {
        setUserList(res.data.users);
      })
      .catch((err) => {
        console.log("UNVERIFIED INSTA USER LIST BY SEARCH ERROR==>", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verifyOtp = () => {
    const myValues = {
      userId: verifiableUser,
      otp,
    };
    verifyInstaProfileAPI(myValues, user?.token)
      .then((res) => {
        message.success("Action taken successfully");
        setVerifiableUser("");
        onClose();
        getAllUnverifiedInstaUser();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   Drawer
  const showDrawer = (id) => {
    setOpen(true);
    setVerifiableUser(id);
  };
  const onClose = () => {
    setOpen(false);
    setVerifiableUser("");
  };
  // COLUMNS of table
  const columns = [
    {
      title: "Name",
      key: "name",
      width: 200,
      fixed: "left",
      render: (_, text) => (
        <Link
          href={`/user/${text?.userId?._id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text?.userId?.name}
        </Link>
      ),
    },
    {
      title: "Insta Username",
      dataIndex: "instaUsername",
      key: "instaUsername",
      width: 200,
      render: (text) => (
        <Link
          href={`https://instagram.com/${text}`}
          target="_blank"
          rel="noopener noreferrer"
          type="success"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "OTP",
      dataIndex: "otp",
      key: "otp",
      width: 100,
      render: (text) => <Text type="warning">{text}</Text>,
    },
    {
      title: "Action",
      key: "action",
      width: 300,
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => showDrawer(record?.userId?._id)}
            style={{ display: user?.role !== "admin" && "none" }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <DesktopLayout>
      <Drawer
        title="Verify OTP"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Row gutter={[16, 24]}>
          <Col span={14}>
            <NormalLabel title="Enter OTP" identifier="otp" />
            <Input
              id="otp"
              placeholder="Enter the OTP received on instagram"
              name="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </Col>
          <Col span={10}>
            <NormalLabel title="" identifier="verify" />

            <Button onClick={verifyOtp} style={{ width: "100%" }} id="verify">
              Verify
            </Button>
          </Col>
        </Row>
      </Drawer>
      <UnverifiedInstaWrapper className="card_style">
        <CardHeading title="List of all user whose Insta Profile is not verified" />
        <div className="mt2 mb1" style={{ width: "100%", height: 10 }} />
        <Row gutter={[16, 24]}>
          <Col xs={24} lg={14}>
            <Input
              placeholder="Search user by insta username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              prefix={<Icons.SearchIcon onClick={getUserBySearch} />}
            />
          </Col>
          <Col xs={12} lg={5}>
            <Button icon={<Icons.SearchIcon />} onClick={getUserBySearch}>
              Search
            </Button>
          </Col>
          <Col xs={12} lg={5}>
            <Button onClick={getAllUnverifiedInstaUser}>Reset</Button>
          </Col>
        </Row>
        <div className="mt2 mb2" style={{ width: "100%", height: 10 }} />
        <Table
          columns={columns}
          dataSource={userList}
          loading={loading}
          scroll={{
            x: "calc(600px + 50%)",
          }}
        />
      </UnverifiedInstaWrapper>
    </DesktopLayout>
  );
};

export default UnverifiedInstaUser;
