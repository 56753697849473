import React, { useEffect, useState } from "react";
import DesktopLayout from "../../../theme/DesktopLayout";
import { AllReelsWrapper } from "./AllReelsStyle";
import { Avatar, message, Table, Tag } from "antd";
import { Typography } from "@mui/material";
import { formatDate, formatDateWithTime } from "../../../config/Function";
import { getReelsAPI, deleteReelsAPI } from "../../../functions/reels";
import { useSelector } from "react-redux";
const { Column } = Table;

const AllReels = () => {
  const user = useSelector((state) => state.user);
  const [reels, setReels] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalReelsCount, setTotalReelsCount] = useState(0);

  useEffect(() => {
    if (user !== "") {
      getAllReels();
    }
  }, [user, page, limit]);

  const getAllReels = () => {
    getReelsAPI(page, limit, user?.token)
      .then((res) => {
        console.log(res.data);
        setReels(res.data.reels);
        setTotalReelsCount(res.data.totalReelsCount);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.message);
      });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination?.current);
    setLimit(pagination?.pageSize);
  };

  const deleteReel = (reelId) => {
    console.log("REEL ID", reelId);
    if (window.confirm("Are you sure you want to delete?")) {
      deleteReelsAPI(reelId, user?.token)
        .then((res) => {
          console.log(res.data);
          message.success("Reel deleted successfully");
          getAllReels();
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
        });
    }
  };
  return (
    <DesktopLayout>
      <AllReelsWrapper>
        <Table
          onChange={handleTableChange}
          pagination={{
            current: page,
            pageSize: limit,
            pageSizeOptions: [20, 50, 75, 100],
            total: totalReelsCount,
          }}
          dataSource={reels}
          scroll={{
            y: 540,
            x: 1200,
          }}
        >
          <Column
            width={150}
            title="Display Name"
            dataIndex="displayName"
            key="displayName"
            fixed="left"
          />

          <Column
            title="Uploaded On"
            dataIndex="createdAt"
            key="createdAt"
            width={200}
            render={(item) => (
              <Typography>
                {" "}
                {formatDate(item)}, ({formatDateWithTime(item)}){" "}
              </Typography>
            )}
          />
          <Column
            title="Reel"
            dataIndex="videoUrl"
            key="videoUrl"
            width={120}
            render={(item) => {
              return (
                <a href={item} target="_blank" rel="noopener noreferrer">
                  <Avatar
                    src={item?.replace(".mp4", ".jpg")}
                    size="large"
                    shape="square"
                  />
                </a>
              );
            }}
          />
          <Column
            title="Categories"
            dataIndex="categories"
            key="categories"
            width={300}
            render={(categories) => (
              <>
                {categories?.map((tag, i) => (
                  <Tag color="blue" key={i}>
                    {tag?.categoryName}
                  </Tag>
                ))}
              </>
            )}
          />
          <Column
            title="Description"
            dataIndex="description"
            key="description"
          />
          <Column
            title="Action"
            key="action"
            render={(_, record) => (
              <Tag
                color="red"
                onClick={() => deleteReel(record._id)}
                style={{ cursor: "pointer" }}
              >
                Delete
              </Tag>
            )}
          />
        </Table>
      </AllReelsWrapper>
    </DesktopLayout>
  );
};

export default AllReels;
