import moment from "moment";

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const calculatePercentage = (myValue, totalValue) => {
  return Math.round((myValue / totalValue) * 100);
};

export const nFormatter = (num) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(1).replace(rx, "$1") + item.symbol
    : "0";
};

export const formatDate = (date) => {
  return moment(date).format("DD/MM/YY");
};

export const formatDateWithTime = (date) => {
  return moment(date).format("LT");
};
export const formatRelativeDate = (date) => {
  return moment(date).fromNow();
};
// program to convert first letter of a string to uppercase
export const capitalizeFirstLetter = (str) => {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
};

// take the value of gender and return a proper label
export const genderIdentifier = (val) => {
  console.log("GENDER IDENTIFIER", val);
  const x = val.toLowerCase();
  const gender =
    x === "male"
      ? "Male"
      : x === "female"
      ? "Female"
      : x === "male & female"
      ? "Both"
      : x === "others"
      ? "Others"
      : "All";
  return gender;
};

export function calculateAge(timestamp) {
  const birthDate = new Date(timestamp);
  const currentDate = new Date();

  // Calculate the age in milliseconds
  const ageInMilliseconds = currentDate.getTime() - birthDate.getTime();

  // Convert milliseconds to years
  const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

  // Round the age to two decimal places
  const roundedAge = Math.round(ageInYears * 100) / 100;

  return roundedAge;
}
