import { Avatar, IconButton, Typography } from "@mui/material";
import {
  Dropdown,
  Empty,
  message,
  Pagination,
  Progress,
  Spin,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../../config/Colors";

import { calculatePercentage } from "../../../../config/Function";
import { Icons } from "../../../../config/IconsList";
import {
  CategoryList,
  UserRole,
  VerificationStatus,
} from "../../../../config/Values";
import {
  deleteCampaignAPI,
  getCampaignAPI,
} from "../../../../functions/campaign";
import useViewport from "../../../../viewport/useViewport";
import EditCampaign from "../../../drawer/EditCampaign/EditCampaign";
import { AllCampaignTableWrapper, PaginationWrapper } from "./Style";

const AllCampaignTable = () => {
  const { isTablet, isMobile } = useViewport();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const search = useSelector((state) => state.search);
  //   different states

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [role, setRole] = useState("all");
  const [status, setStatus] = useState("all");
  const [category, setCategory] = useState("all");
  const [expanded, setExpanded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [campaignsList, setCampaignsList] = useState([]);
  const [editableCampaignData, setEditableCampaignData] = useState({});
  const [loadingCampaign, setLoadingCampaign] = useState(false);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({
    filterType: "status",
    filterValue: "all",
  });
  const showDrawer = () => {
    setOpen(true);
    setEditableCampaignData({});
  };
  const onClose = (campaignData) => {
    setOpen(false);
    setEditableCampaignData(campaignData);
  };
  // =================================================================
  // =============================useEffect===========================
  // =================================================================
  useEffect(() => {
    getAllCampaigns();
  }, [pageSize, currentPage, user, category]);
  useEffect(() => {
    getAllCampaignsBySearch();
  }, [search]);
  //   ==============================FUNCTIONS==================================
  // page  size change: number of item to be displayed on a single page
  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
    setPageSize(pageSize);
  };
  // change page number
  const handleChangePageNumber = (page, pageSize) => {
    setCurrentPage(page);
  };

  //   change role
  const handleRoleChange = ({ key }) => {
    let myRoleObj = UserRole?.find((item) => item.key === key);
    console.log("HELLO", myRoleObj.role);
    setRole(myRoleObj.role);
    setFilter({
      filterType: "role",
      filterValue: myRoleObj.role,
    });
  };
  //   change status
  const handleStatusChange = ({ key }) => {
    let myRoleObj = VerificationStatus?.find((item) => item.key === key);
    console.log("HELLO", myRoleObj.status);
    setStatus(myRoleObj.status);
    setFilter({
      filterType: "status",
      filterValue: myRoleObj.status,
    });
  };

  const handleCategoryChange = ({ key }) => {
    let catObj = CategoryList?.find((item) => item.key === key);

    setCategory(catObj.status);
    setFilter({
      filterType: "category",
      filterValue: catObj.status,
    });
  };
  //   toggle search bar width
  const handleSearchInputExpansion = (e) => {
    e.preventDefault();
    setExpanded(true);
    if (searchText !== "") {
      // run api for search
      console.log(searchText);
    }
  };

  const getAllCampaigns = () => {
    setLoadingCampaign(true);
    const values = {
      page: currentPage,
      limit: pageSize,
      ...filter,
    };
    getCampaignAPI(values, user?.token)
      .then((res) => {
        console.log("CAMPAIGNS=>", res.data);
        setCampaignsList(res.data.campaigns);
        setLoadingCampaign(false);
      })
      .catch((err) => console.log(err));
  };

  const getAllCampaignsBySearch = () => {
    setLoadingCampaign(true);
    const values = {
      page: currentPage,
      limit: pageSize,
      filterType: search.searchType,
      filterValue: search.searchValue,
    };
    getCampaignAPI(values, user?.token)
      .then((res) => {
        console.log("CAMPAIGNS=>", res.data);
        setCampaignsList(res.data.campaigns);
        setLoadingCampaign(false);
      })
      .catch((err) => console.log(err));
  };

  //   delete the campaign
  const handleDeleteCampaign = (campaignId) => {
    if (window.confirm("Do you want to delete this campaign?")) {
      deleteCampaignAPI(campaignId, user.token)
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            message.success("The campaign has been deleted successfully.😊");
          } else {
            message.warning("Opps!!! Something went wrong.");
          }
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message);
        });
    } else {
      console.log("Request canceled!!");
    }
  };

  return (
    <>
      <EditCampaign
        campaignData={editableCampaignData}
        onClose={onClose}
        open={open}
        setOpen={setOpen}
        showDrawer={showDrawer}
      />
      <AllCampaignTableWrapper
        isTablet={isTablet}
        isMobile={isMobile}
        expanded={expanded}
      >
        <div
          className="flexCenter"
          style={{ flexDirection: "column", width: "100%" }}
        >
          <table className="tableParent" style={{ width: "100%" }}>
            {/* table header row */}
            <thead className="headerContainer">
              <tr>
                <th className="tableHeader flexBetween">
                  <p>Campaign Name</p>
                </th>

                <th className="tableHeader centerAlignText">
                  <Dropdown
                    menu={{
                      items: CategoryList,
                      onClick: handleCategoryChange,
                    }}
                    placement="bottom"
                  >
                    <span style={{ cursor: "pointer" }}>Category</span>
                  </Dropdown>
                </th>
                <th className="tableHeader centerAlignText">Price</th>
                <th className="tableHeader centerAlignText">Status </th>
                <th className="tableHeader centerAlignText">Action</th>
              </tr>
            </thead>

            {/* table body */}
            <tbody>
              {campaignsList?.slice(0, pageSize)?.map((campaign, i) => {
                let completionPercent = calculatePercentage(
                  campaign?.shortlistedInfluencer?.length + 10,
                  campaign.influencerRequired
                );
                return (
                  <tr key={i}>
                    {/* name and avatar */}
                    <td style={{ whiteSpace: "pre-wrap", maxWidth: "15rem" }}>
                      <a
                        href={`/campaign/${campaign?._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="tableData"
                      >
                        <Avatar
                          variant="rounded"
                          sx={{ width: 37, height: 37 }}
                          style={{
                            borderRadius: 10,
                            display: isMobile && "none",
                          }}
                          src={campaign?.campaignBanner}
                        />
                        <div className="influencerText">
                          <Typography className="name">
                            {campaign?.campaignName}{" "}
                          </Typography>
                          <Typography className="brandName">
                            {campaign?.brand?.name}
                          </Typography>
                        </div>
                      </a>
                    </td>

                    {/* categories */}
                    <td
                      className="centerAlignText"
                      style={{ flexWrap: "wrap", maxWidth: "12rem" }}
                    >
                      {campaign?.campaignCategories?.map((item, i) => {
                        return (
                          <Tag
                            key={i}
                            style={{
                              color: Colors.white,
                              textTransform: "capitalize",
                              fontSize: 8,
                              marginTop: 8,
                            }}
                            color={Colors.tableText}
                          >
                            {item.categoryName}
                          </Tag>
                        );
                      })}
                    </td>
                    {/* campaign price */}
                    <td className="centerAlignText">
                      {campaign?.campaignPrice
                        ? campaign?.campaignPrice
                        : "250000"}
                    </td>

                    {/* status */}
                    <td className="centerAlignText">
                      <Progress
                        percent={completionPercent}
                        strokeLinecap="round"
                        status={
                          campaign.isCanceled
                            ? "exception"
                            : completionPercent === 100
                            ? "success"
                            : "active"
                        }
                        strokeColor={
                          campaign.isCanceled
                            ? Colors.alertRed
                            : completionPercent < 50
                            ? Colors.lightOrange
                            : Colors.lightGreen
                        }
                      />
                    </td>
                    {/* action */}

                    <td className="centerAlignText">
                      <a
                        href={`/campaign/${campaign?._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <IconButton className="iconButton">
                          <Icons.VisibilityIcon className="actionIcon" />
                        </IconButton>
                      </a>
                      <IconButton
                        className="iconButton"
                        onClick={() =>
                          navigate(`/edit/${campaign?._id}/campaign`)
                        }
                      >
                        <Icons.EditIcon className="actionIcon" />
                      </IconButton>
                      <IconButton
                        className="iconButton"
                        onClick={() => handleDeleteCampaign(campaign?._id)}
                      >
                        <Icons.DeleteIcon className="actionIcon" />
                      </IconButton>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {campaignsList?.length < 1 && (
            <div
              className="flexCenter mt2"
              style={{
                width: "100%",
                alignSelf: "center",
              }}
            >
              <Empty />
            </div>
          )}
          {loadingCampaign && (
            <div
              className="flexCenter mt2"
              style={{
                width: "100%",
                alignSelf: "center",
              }}
            >
              <Spin />
              <br />
              <Typography>Loading...</Typography>
            </div>
          )}
        </div>
      </AllCampaignTableWrapper>
      {/* pagination */}
      <PaginationWrapper>
        <Pagination
          pageSizeOptions={[4, 10, 50, 100]}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={1}
          total={500}
          onChange={handleChangePageNumber}
        />
      </PaginationWrapper>
    </>
  );
};

export default AllCampaignTable;
